import { FC, useCallback } from 'react';

import { Heading } from 'components/UI/Heading';
import { List } from 'components/UI/List';
import { Modal } from 'components/UI/Modal';
import { ScopeTranslation } from 'components/UI/ScopeTranslation';
import { useTranslations } from 'hooks/useTranslations';
import { useGetUserGrantsQuery } from 'store/diamApi';
import { ConnectedApplication, Description, ScopesContainer } from './styled';

type Props = {
  isOpen: boolean;
  close: () => void;
};

export const ConnectedApplicationsModal: FC<Props> = ({ isOpen, close }) => {
  const t = useTranslations();
  const userGrants = useGetUserGrantsQuery();

  // Close
  const onClose = useCallback(() => close(), [close]);

  return (
    <Modal
      title={t('account_security.action_connected_applications')}
      isOpen={isOpen}
      isLoading={userGrants.isLoading}
      onClose={onClose}
    >
      <Description>
        {t('account_security.description_connected_applications')}
      </Description>
      <List>
        {userGrants.data?.map(({ id, clientId, clientDescription, scopes }) => (
          <ConnectedApplication key={id}>
            <Heading type="h3">{clientDescription || clientId}</Heading>
            <Description>
              {t('account_security.description_connected_applications_scopes')}
            </Description>
            <ScopesContainer>
              {scopes.map((scopeId) => (
                <ScopeTranslation key={scopeId} scopeId={scopeId} />
              ))}
            </ScopesContainer>
          </ConnectedApplication>
        ))}
      </List>
    </Modal>
  );
};
