import styled from 'styled-components';
import { breakpoints } from 'styles';

export const Container = styled.footer`
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 16px;
  margin: auto auto 0;
`;

export const Logo = styled.img`
  margin-bottom: 8px;
`;

export const Text = styled.p`
  color: ${({ theme }) => theme.colors.subtle};
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  margin: 0;
`;

export const Links = styled.div`
  display: flex;
  justify-content: center;
  gap: 16px;
  margin-top: 16px;

  @media only screen and (max-width: ${breakpoints.s}px) {
    flex-direction: column;
  }
`;

export const Link = styled.a`
  color: ${({ theme }) => theme.colors.onBackground};
  font-family: Roboto, Arial, sans-serif;
  font-size: 14px;
  line-height: 16px;
  font-weight: 350;
  text-decoration: underline;
  letter-spacing: 0.4px;
  text-align: center;
  -webkit-font-smoothing: antialiased;
`;
