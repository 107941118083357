/* istanbul ignore file */
import { FC, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { ErrorDisplay } from 'components/fragments/ErrorDisplay';
import { ErrorState } from 'components/UI/ErrorState';
import { Loader } from 'components/UI/Loader';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useTranslations } from 'hooks/useTranslations';
import { Router } from 'router';
import { useGetTranslationsQuery } from 'store/diamApi';
import {
  fetchTranslation,
  localizationSelector,
  setLanguage
} from 'store/localizationState';
import { ThemeWrapper } from 'styles';

export const App: FC = () => {
  const { lang } = useParams();
  const t = useTranslations();
  const dispatch = useAppDispatch();
  const { languageCode } = useAppSelector(localizationSelector);
  const { data, isLoading, isError } = useGetTranslationsQuery();

  // Determine and set language
  useEffect(() => {
    if (data) {
      dispatch(setLanguage(data, lang));
    }
  }, [dispatch, lang, data]);

  // Fetch translations
  useEffect(() => {
    if (languageCode) {
      dispatch(fetchTranslation(languageCode));
    }
  }, [dispatch, languageCode]);

  // Wait for translations
  const content = useMemo(() => {
    if (isLoading) {
      return <Loader />;
    }

    if (!data || isError) {
      return <ErrorState message={t('errors.unknown_error')} />;
    }

    return (
      <>
        <Router />
        <ErrorDisplay />
      </>
    );
  }, [t, data, isLoading, isError]);

  return <ThemeWrapper>{content}</ThemeWrapper>;
};
