import { ConnectorConfigBuilder } from 'diam-connector/config/connector-config';
import { ConnectorFactory } from 'diam-connector/connector-factory';
import { ServiceNameRequestIdHandler } from 'diam-connector/handlers';
import { Token as DiamToken } from 'diam-connector/models/token';
import { User as DiamUser } from 'diam-connector/models/user';

import * as config from 'config';
import { LocalStorageSession } from 'models/auth';
import { JsonApiErrorDto } from 'models/jsonApiError';
import {
  ActiveSession,
  Country,
  ExtendedUser,
  ExtendedUserResponse,
  Language,
  ResendActivation,
  ScopeTranslation,
  ScopeTranslationsResponse,
  Token,
  User,
  UserGrant,
  UserMeta
} from 'models/diam';
import {
  getExtendedUserFromResponse,
  getScopeTranslationsFromResponse,
  serializeResult
} from 'utils/diamConnectorUtils';
import * as localStorage from 'utils/localStorageUtils';
import { getCodeVerifier } from 'utils/loginUtils';

const requestIdHandler = new ServiceNameRequestIdHandler(config.clientId);
const diamConfig = ConnectorConfigBuilder.create(config.diamApi)
  .withExternalRequestIdHandler(requestIdHandler)
  .build();
const diamConnector = new ConnectorFactory(diamConfig);

export async function resendActivation(
  email: string,
  meta: UserMeta = {},
  connector = diamConnector
): Promise<ResendActivation> {
  const result = await connector.accountConnector.resendActivation(email, meta);

  return serializeResult<ResendActivation>(result);
}

export async function activateUser(
  userId: string,
  activationCode: string,
  connector = diamConnector
): Promise<ExtendedUser> {
  const res = (await connector.genericConnector.post(
    `/users/${userId}/activations/${activationCode}`
  )) as ExtendedUserResponse;

  return getExtendedUserFromResponse(res);
}

export async function recoverPassword(
  email: string,
  meta: UserMeta = {},
  connector = diamConnector
): Promise<void> {
  const result = await connector.accountConnector.recoverPassword(email, meta);

  return serializeResult<void>(result);
}

export async function validatePasswordRecoveryCode(
  userId: string,
  recoveryCode: string,
  connector = diamConnector
): Promise<ExtendedUser> {
  const res: ExtendedUserResponse = await connector.genericConnector.get(
    `/users/${userId}/recovery/${recoveryCode}?include=groups&include=password-policy`
  );

  return getExtendedUserFromResponse(res);
}

export async function setNewPassword(
  userId: string,
  recoveryCode: string,
  newPassword: string,
  connector = diamConnector
): Promise<void> {
  const result = await connector.genericConnector.post(
    `/users/${userId}/setpassword/${recoveryCode}`,
    undefined,
    {
      type: 'users',
      attributes: {
        new_password: newPassword,
        confirmed_new_password: newPassword
      }
    }
  );

  return serializeResult<void>(result);
}

export async function updatePassword(
  token: DiamToken,
  oldPassword: string,
  newPassword: string,
  connector = diamConnector
): Promise<void> {
  const result = await connector.accountConnector.updatePassword(
    token,
    token.userId,
    oldPassword,
    newPassword
  );

  return serializeResult<void>(result);
}

export async function changeUsername(
  token: DiamToken,
  newUsername: string,
  password: string,
  meta: UserMeta = {},
  connector = diamConnector
): Promise<void> {
  const result = await connector.accountConnector.changeUsername(
    token,
    token.userId,
    newUsername,
    password,
    meta
  );

  return serializeResult<void>(result);
}

export async function validateUsernameChangeCode(
  userId: string,
  usernameChangeCode: string,
  connector = diamConnector
): Promise<ExtendedUser> {
  const res: ExtendedUserResponse = await connector.genericConnector.get(
    `/users/${userId}/validate-changeusername/${usernameChangeCode}`
  );

  return getExtendedUserFromResponse(res);
}

export async function confirmUsernameChange(
  userId: string,
  usernameChangeCode: string,
  password: string,
  connector = diamConnector
): Promise<ExtendedUser> {
  const res: ExtendedUserResponse = await connector.genericConnector.post(
    `/users/${userId}/confirm-changeusername`,
    undefined,
    {
      type: 'users',
      attributes: {
        code: usernameChangeCode,
        password
      }
    }
  );

  return getExtendedUserFromResponse(res);
}

export async function deleteUser(
  token: DiamToken,
  meta: UserMeta = {},
  connector = diamConnector
): Promise<void> {
  const result = await connector.accountConnector.requestDeleteUser(
    token.userId,
    meta
  );

  return serializeResult<void>(result);
}

export async function validateUserDeletionCode(
  userId: string,
  deletionCode: string,
  connector = diamConnector
): Promise<ExtendedUser> {
  const res: ExtendedUserResponse = await connector.genericConnector.get(
    `/users/${userId}/deletion/${deletionCode}`
  );

  return getExtendedUserFromResponse(res);
}

export async function confirmUserDeletion(
  userId: string,
  deletionCode: string,
  password: string,
  connector = diamConnector
): Promise<void> {
  const result = await connector.genericConnector.post(
    `/users/${userId}/deletion`,
    undefined,
    {
      type: 'users',
      attributes: {
        delete_code: deletionCode,
        password
      }
    }
  );

  return serializeResult<void>(result);
}

export async function getLanguages(
  connector = diamConnector
): Promise<Language[]> {
  const result = await connector.basicDataConnector.findLanguages();

  return serializeResult<Language[]>(result);
}

export async function getTranslations(
  connector = diamConnector
): Promise<Language[]> {
  const result = await connector.basicDataConnector.findTranslations();

  return serializeResult<Language[]>(result);
}

export async function getCountries(
  connector = diamConnector
): Promise<Country[]> {
  const result = await connector.basicDataConnector.findCountries();

  return serializeResult<Country[]>(result);
}

export async function getScopeTranslations(
  token: DiamToken,
  scopeId: string,
  connector = diamConnector
): Promise<ScopeTranslation[]> {
  const res: ScopeTranslationsResponse = await connector.genericConnector.get(
    `/scopes/${scopeId}/translations`,
    token
  );

  return getScopeTranslationsFromResponse(res);
}

export async function loginWithAuthorizationCode(
  code: string,
  clientId: string = config.clientId,
  clientSecret?: string,
  redirectUri: string = `${window.location.origin}/authorize`,
  codeVerifier: string = getCodeVerifier(),
  connector = diamConnector
): Promise<Token> {
  const result = await connector.tokenConnector.loginWithAuthorizationCode(
    code,
    clientId,
    clientSecret,
    redirectUri,
    codeVerifier
  );

  return serializeResult<Token>(result);
}

export async function loginWithRefreshToken(
  token: DiamToken,
  clientId: string = config.clientId,
  connector = diamConnector
): Promise<Token> {
  if (!token.refreshToken) {
    throw new JsonApiErrorDto('Refresh token not found', {
      code: 'refresh.token.not.found',
      status: 404
    });
  }

  const result = await connector.tokenConnector.loginWithRefreshToken(
    token.refreshToken,
    clientId
  );

  return serializeResult<Token>(result);
}

export async function loginWithValidateToken(
  storage = localStorage,
  connector = diamConnector
): Promise<Token> {
  const session = storage.getItem<LocalStorageSession>('session');

  if (!session) {
    throw new JsonApiErrorDto('Session not found', {
      code: 'session.not.found',
      status: 404
    });
  }

  const { accessToken, refreshToken, userId } = session;
  const token = new DiamToken({ accessToken, refreshToken, userId });

  try {
    await connector.tokenConnector.validateJWT(accessToken, true);
    return serializeResult<Token>(token);
  } catch (e) {
    return loginWithRefreshToken(token);
  }
}

export async function logout(
  token: DiamToken,
  connector = diamConnector
): Promise<void> {
  const result = await connector.tokenConnector.logout(token.accessToken);

  return serializeResult<void>(result);
}

export async function logoutEverywhere(
  token: DiamToken,
  connector = diamConnector
): Promise<void> {
  const result = await connector.tokenConnector.logoutEverywhere(
    token,
    token.userId
  );

  return serializeResult<void>(result);
}

export async function getUser(
  token: DiamToken,
  connector = diamConnector
): Promise<ExtendedUser> {
  const res: ExtendedUserResponse = await connector.genericConnector.get(
    `/users/${token.userId}?include=groups&include=password-policy`,
    token
  );

  return getExtendedUserFromResponse(res);
}

export async function updateUser(
  token: DiamToken,
  user: Partial<User>,
  connector = diamConnector
): Promise<User> {
  const result = await connector.userConnector.update(
    token,
    new DiamUser({ ...user, id: token.userId })
  );

  return serializeResult<User>(result);
}

export async function getUserGrants(
  token: DiamToken,
  connector = diamConnector
): Promise<UserGrant[]> {
  const result = await connector.userConnector.findUserGrants(
    token,
    token.userId,
    true
  );

  return serializeResult<UserGrant[]>(result);
}

export async function deleteUserGrant(
  token: DiamToken,
  clientId: string,
  connector = diamConnector
): Promise<void> {
  const result = await connector.userConnector.removeUserGrant(
    token,
    token.userId,
    clientId
  );

  return serializeResult<void>(result);
}

export async function getActiveSessions(
  token: DiamToken,
  connector = diamConnector
): Promise<ActiveSession[]> {
  const result = await connector.userConnector.findActiveSessions(
    token,
    token.userId,
    true
  );

  // Filter out the internal client
  const filtered = result.filter(
    (item) => item.clientId !== 'iam-internal-oauth2-login-client'
  );

  return serializeResult<ActiveSession[]>(filtered);
}
