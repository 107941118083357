import styled, { DefaultTheme } from 'styled-components';

type Props = {
  disabled?: boolean;
  $isOpen?: boolean;
  $error?: boolean;
};

type StyleProps = Props & {
  theme: DefaultTheme;
};

type OptionProps = {
  $highlight: boolean;
};

// Handle background color
function handleBackgroundColor({ theme, disabled }: StyleProps) {
  if (disabled) {
    return theme.colors.lightGray;
  }

  return theme.colors.background;
}

// Handle text and border color
function handleColor({ theme, $error }: StyleProps) {
  if ($error) {
    return theme.colors.error;
  }

  return theme.colors.dark;
}

export const Container = styled.div`
  width: 100%;
  height: 80px;
`;

export const SelectFieldLabel = styled.label<Props>`
  display: inline-block;
  font-size: 16px;
  line-height: 24px;
  color: ${handleColor};
  margin-bottom: 4px;
`;

export const Required = styled.span`
  color: ${({ theme }) => theme.colors.error};
  margin-left: 4px;
`;

export const Message = styled.span`
  font-style: italic;
  margin-left: 4px;
`;

export const SelectFieldContainer = styled.div<Props>`
  width: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  background-color: ${handleBackgroundColor};
  z-index: ${({ $isOpen }) => ($isOpen ? '2' : '1')};
  border: 1px solid ${handleColor};
  border-radius: 8px;
`;

export const SelectFieldGroup = styled.div<Props>`
  width: 100%;
  display: flex;
  border-bottom: ${({ $isOpen, theme }) =>
    $isOpen ? `1px solid ${theme.colors.lightGray}` : 0};
`;

export const SelectFieldInput = styled.input`
  width: 100%;
  background-color: transparent;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.dark};
  caret-color: ${({ theme }) => theme.colors.brand90};
  padding: 16px;
  border: none;
  outline: none;
  margin: 0;
  cursor: ${({ readOnly }) => (readOnly ? 'pointer' : 'auto')};
`;

export const SelectFieldButton = styled.button<Props>`
  display: flex;
  align-items: center;
  background: transparent;
  padding: 16px;
  border: none;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
`;

export const Options = styled.ul<Props>`
  width: 100%;
  max-height: 300px;
  display: ${({ $isOpen }) => ($isOpen ? 'block' : 'none')};
  flex-grow: 1;
  background: ${({ theme }) => theme.colors.background};
  padding: 0;
  border-radius: 0 0 8px 8px;
  margin: 0;
  overflow-y: scroll;
`;

export const Option = styled.li<OptionProps>`
  display: flex;
  justify-content: space-between;
  background-color: ${({ theme, $highlight }) =>
    $highlight ? theme.colors.brand5 : 'transparent'};
  color: ${({ theme }) => theme.colors.dark};
  padding: 8px 16px;
  cursor: pointer;
  transition: background-color 0.2s ease-out;

  &:hover {
    background-color: ${({ theme }) => theme.colors.brand5};
  }
`;

export const OptionContent = styled.div`
  display: flex;
  gap: 12px;
`;

export const OptionText = styled.span`
  font-size: 16px;
  line-height: 24px;
  margin: 0;
`;

export const Mark = styled.mark`
  background-color: ${({ theme }) => theme.colors.brand5};
  color: ${({ theme }) => theme.colors.primary};
  font-weight: bold;
`;
