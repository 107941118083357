import styled from 'styled-components';

import { breakpoints } from 'styles';

export const PageContainer = styled.div`
  display: flex;
  height: 100%;
`;

export const Content = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  overflow-y: scroll;
  position: relative;

  @media (max-width: ${breakpoints.l}px) {
    width: 100%;
  }
`;

export const Header = styled.header`
  width: 100%;
`;

export const HeaderLogo = styled.img`
  width: 84px;
  display: block;
  margin: 88px auto 48px;

  @media only screen and (min-height: ${breakpoints.l}px) {
    margin: 88px auto 112px;
  }
`;

export const Main = styled.main`
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 450px;
  min-height: fit-content;
  margin: 0;
`;
