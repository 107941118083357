import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;

  a {
    width: auto;
    padding: 0;

    &:hover {
      background-color: transparent;
    }
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
`;
