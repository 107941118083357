import { useEffect } from 'react';

type UseKeyEffectOptions = {
  /** The action to perform when the key is pressed. Should be memoized (e.g useCallback) for performance purposes */
  action: (event: KeyboardEvent) => any;
  /** If we should initialize the listener */
  addEventListener: boolean;
  /** The keyboard (see https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/key) event key that should trigger the provided `action`. */
  eventKeys: KeyboardEvent['key'][];
  /** The element to which the event listener should be added */
  target: any;
};

export const useKeyEffect = ({
  action,
  addEventListener,
  eventKeys,
  target
}: UseKeyEffectOptions) => {
  useEffect(() => {
    const keyboardEvent = (event: KeyboardEvent) => {
      if (eventKeys.includes(event.key)) {
        action(event);
      }
    };

    if (addEventListener && target) {
      target.addEventListener('keydown', keyboardEvent);
    }

    return () => {
      target?.removeEventListener('keydown', keyboardEvent);
    };
  }, [action, addEventListener, eventKeys, target]);
};
