import { FC, ReactNode } from 'react';
import { To } from 'react-router-dom';

import { Content, StyledLink } from './styled';

type LinkVariant = 'primary' | 'secondary' | 'transparent';

type Props = {
  children?: ReactNode;
  to: To;
  target?: string;
  rel?: string;
  variant?: LinkVariant;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
};

export const Link: FC<Props> = ({
  children,
  to,
  target,
  rel,
  variant = 'primary',
  onClick
}) => (
  <StyledLink
    to={to}
    target={target}
    rel={rel}
    $variant={variant}
    onClick={onClick}
  >
    <Content>{children}</Content>
  </StyledLink>
);
