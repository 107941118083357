import styled from 'styled-components';

export const Description = styled.p`
  margin-bottom: 24px;
`;

export const InputFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 32px;
`;
