import { FC, useCallback, useMemo } from 'react';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';

import { Button } from 'components/UI/Button';
import { ErrorState } from 'components/UI/ErrorState';
import { Heading } from 'components/UI/Heading';
import { InputField } from 'components/UI/InputField';
import { Loader } from 'components/UI/Loader';
import { SuccessState } from 'components/UI/SuccessState';
import { UserGroups } from 'components/UI/UserGroups';
import { useQuery } from 'hooks/useQuery';
import { useTranslations } from 'hooks/useTranslations';
import * as routes from 'router/routes';
import {
  useConfirmUserDeletionMutation,
  useValidateUserDeletionCodeQuery
} from 'store/diamApi';
import { getUserGroups } from 'utils/userUtils';
import {
  Description,
  InputFieldContainer,
  UserGroupsContainer
} from './styled';

export const DeleteAccountPage: FC = () => {
  const t = useTranslations();
  const [code, uid] = useQuery('code', 'uid');
  const validateUserDeletionCode = useValidateUserDeletionCodeQuery({
    userId: uid ?? '',
    deletionCode: code ?? ''
  });
  const [confirmUserDeletion, confirmUserDeletionResult] =
    useConfirmUserDeletionMutation();

  // useForm
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  // Submit
  const onSubmit: SubmitHandler<FieldValues> = useCallback(
    ({ password }) => {
      confirmUserDeletion({
        userId: uid ?? '',
        deletionCode: code ?? '',
        password
      });
    },
    [code, confirmUserDeletion, uid]
  );

  const errorMessage = useMemo(
    () =>
      validateUserDeletionCode.error?.code === 'deletion.code.expired'
        ? 'delete_account.link_expired'
        : 'delete_account.link_invalid',
    [validateUserDeletionCode.error?.code]
  );

  if (
    validateUserDeletionCode.isLoading ||
    confirmUserDeletionResult.isLoading
  ) {
    return <Loader />;
  }

  if (!validateUserDeletionCode.data || validateUserDeletionCode.isError) {
    return (
      <ErrorState
        message={t(errorMessage)}
        link={{ url: routes.LOGIN, text: t('delete_account.request_new_link') }}
      />
    );
  }

  if (confirmUserDeletionResult.isSuccess) {
    const { forward } = validateUserDeletionCode.data.meta;

    return (
      <SuccessState
        heading={t('delete_account.title_success')}
        description={t('delete_account.description_success')}
        forward={forward}
        link={{ url: routes.LOGIN, text: t('general.go_to_login') }}
        hideBackButton
      />
    );
  }

  return (
    <>
      <Heading>{t('delete_account.title')}</Heading>
      <Description>
        {t('delete_account.description_delete_account_services', {
          email: validateUserDeletionCode.data?.username
        })}
      </Description>
      <UserGroupsContainer>
        <UserGroups groups={getUserGroups(validateUserDeletionCode.data)} />
      </UserGroupsContainer>
      <Description>
        {t('delete_account.description_delete_account_enter_password', {
          email: validateUserDeletionCode.data?.meta.new_username
        })}
      </Description>
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputFieldContainer>
          <InputField
            id="password"
            label={t('account_security.label_password')}
            required
            isPasswordInput
            placeholder={t('account_security.placeholder_password')}
            error={errors.password}
            register={register('password', {
              required: {
                value: true,
                message: t('validation.required')
              }
            })}
          />
        </InputFieldContainer>
        <Button type="submit" variant="danger">
          {t('delete_account.delete_my_account')}
        </Button>
      </form>
    </>
  );
};
