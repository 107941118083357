import { FC, useCallback, useState } from 'react';

import { FaqModal } from 'components/modals/FaqModal';
import { Heading } from 'components/UI/Heading';
import { List } from 'components/UI/List';
import { ListItem } from 'components/UI/ListItem';
import { ListItemLink } from 'components/UI/ListItemLink';
import { Section } from 'components/UI/Section';
import { useTranslations } from 'hooks/useTranslations';
import { privacyPolicyUrl } from 'utils/constants';
import { SupportModal } from 'components/modals/SupportModal';

export const LearnMorePage: FC = () => {
  const t = useTranslations();
  const [faqModal, setFaqModal] = useState<boolean>(false);
  const [supportModal, setSupportModal] = useState<boolean>(false);

  const onFaqOpen = useCallback(() => setFaqModal(true), []);
  const onFaqClose = useCallback(() => setFaqModal(false), []);
  const onSupportOpen = useCallback(() => setSupportModal(true), []);
  const onSupportClose = useCallback(() => setSupportModal(false), []);

  return (
    <>
      <Heading>{t('learn_more.title')}</Heading>
      <Section>
        <List>
          <ListItem
            headline={t('learn_more.action_faq')}
            supportingText={t('learn_more.action_faq_description')}
            onClick={onFaqOpen}
          />
          <ListItem
            headline={t('learn_more.action_support')}
            supportingText={t('learn_more.action_support_description')}
            onClick={onSupportOpen}
          />
          <ListItemLink
            headline={t('learn_more.action_privacy_portal')}
            supportingText={t('learn_more.action_privacy_portal_description')}
            link={privacyPolicyUrl}
          />
        </List>
      </Section>
      <FaqModal isOpen={faqModal} close={onFaqClose} />
      <SupportModal isOpen={supportModal} close={onSupportClose} />
    </>
  );
};
