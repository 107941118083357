import { ToastContainer } from 'react-toastify';
import styled from 'styled-components';

export const StyledToast = styled(ToastContainer)`
  .Toastify__toast {
    font-weight: 600;
    padding: 16px;
    color: ${({ theme }) => theme.colors.onPrimary};
  }

  .Toastify__progress-bar {
    background-color: ${({ theme }) => `${theme.colors.white}B3`};
  }

  .Toastify__toast--error {
    background-color: ${({ theme }) => theme.colors.error};
  }

  .Toastify__toast--info {
    background-color: ${({ theme }) => theme.colors.info};
  }

  .Toastify__toast--warning {
    background-color: ${({ theme }) => theme.colors.warning};
  }

  .Toastify__toast--success {
    background-color: ${({ theme }) => theme.colors.success};
  }

  .Toastify__toast-icon {
    display: none;
  }

  .Toastify__close-button {
    display: none;
  }
`;
