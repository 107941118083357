import { StatusCodeError } from 'diam-connector/errors/statuscode-error';

export type JsonApiError = {
  code: string;
  status: number;
  title?: string;
  detail?: string;
};

const defaultJsonApiError: JsonApiError = {
  code: 'unknown',
  status: 400
};

export class JsonApiErrorDto extends Error {
  readonly apiError: JsonApiError;

  constructor(message: string, apiError: JsonApiError) {
    super(message);
    this.apiError = apiError;

    Object.setPrototypeOf(this, JsonApiErrorDto.prototype);
  }

  public static fromError(err: any): JsonApiError {
    if (err instanceof JsonApiErrorDto) {
      return err.apiError;
    }

    if (err instanceof StatusCodeError) {
      const error = err.error.errors?.[0] ?? defaultJsonApiError;

      return new JsonApiErrorDto(err.message, error).apiError;
    }

    return new JsonApiErrorDto(err.message, defaultJsonApiError).apiError;
  }
}
