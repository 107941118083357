import { FC, useCallback, useEffect, useMemo } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';

import { ErrorState } from 'components/UI/ErrorState';
import { Loader } from 'components/UI/Loader';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useQuery } from 'hooks/useQuery';
import { useTranslations } from 'hooks/useTranslations';
import * as routes from 'router/routes';
import { authSelector, resetAuthError, ssoRedirect } from 'store/authState';
import { localizationSelector } from 'store/localizationState';
import { Button } from 'components/UI/Button';

export const SsoPage: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const t = useTranslations();
  const { error } = useAppSelector(authSelector);
  const { languageCode } = useAppSelector(localizationSelector);
  const [ssoToken] = useQuery('token');

  useEffect(() => {
    if (ssoToken) {
      dispatch(ssoRedirect(ssoToken, languageCode));
    }
  }, [dispatch, languageCode, ssoToken]);

  const onResetError = useCallback(() => {
    dispatch(resetAuthError());
    navigate(routes.LOGIN);
  }, [dispatch, navigate]);

  const errorMessage = useMemo(
    () =>
      error === 'session.storage.unavailable'
        ? 'errors.session_storage_unavailable'
        : 'errors.unknown_error',
    [error]
  );

  if (!ssoToken) {
    return <Navigate to={routes.LOGIN} />;
  }

  if (error) {
    return (
      <ErrorState message={t(errorMessage)}>
        <Button onClick={onResetError}>{t('general.go_to_login')}</Button>
      </ErrorState>
    );
  }

  return <Loader />;
};
