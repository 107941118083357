import { FC, useMemo } from 'react';

import { ErrorState } from 'components/UI/ErrorState';
import { Loader } from 'components/UI/Loader';
import { SuccessState } from 'components/UI/SuccessState';
import { useQuery } from 'hooks/useQuery';
import { useTranslations } from 'hooks/useTranslations';
import * as routes from 'router/routes';
import { useActivateUserQuery } from 'store/diamApi';

export const ActivateAccountPage: FC = () => {
  const t = useTranslations();
  const [code, uid] = useQuery('code', 'uid');
  const activateUser = useActivateUserQuery({
    userId: uid ?? '',
    activationCode: code ?? ''
  });

  const errorMessage = useMemo(
    () =>
      activateUser.error?.code === 'user.already.activated'
        ? 'activate_account.user_already_activated'
        : 'activate_account.user_cannot_be_activated',
    [activateUser.error?.code]
  );

  const errorLink = useMemo(
    () =>
      activateUser.error?.code === 'user.already.activated'
        ? {
            url: routes.LOGIN,
            text: t('general.go_to_login')
          }
        : {
            url: routes.RESEND_ACTIVATION,
            text: t('activate_account.request_new_link')
          },
    [activateUser.error?.code, t]
  );

  if (activateUser.isLoading) {
    return <Loader />;
  }

  if (!activateUser.data || activateUser.isError) {
    return <ErrorState message={t(errorMessage)} link={errorLink} />;
  }

  const { forward } = activateUser.data.meta;

  return (
    <SuccessState
      heading={t('activate_account.title')}
      description={t('activate_account.description')}
      iconType="check"
      forward={forward}
      link={{ url: routes.LOGIN, text: t('general.go_to_login') }}
      hideBackButton
    />
  );
};
