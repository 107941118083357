import featuredImage1 from 'assets/images/featured-image-1.jpg';
import featuredImage2 from 'assets/images/featured-image-2.jpg';
import featuredImage3 from 'assets/images/featured-image-3.jpg';
import featuredImage4 from 'assets/images/featured-image-4.jpg';
import featuredImage5 from 'assets/images/featured-image-5.jpg';
import featuredImage6 from 'assets/images/featured-image-6.jpg';

export const slideshowImages = [
  featuredImage1,
  featuredImage2,
  featuredImage3,
  featuredImage4,
  featuredImage5,
  featuredImage6
];
