import { FC, useCallback, useState } from 'react';

import { useContext } from 'components/fragments/PrivatePageWrapper';
import { ChangeLanguageModal } from 'components/modals/ChangeLanguageModal';
import { EditAddressModal } from 'components/modals/EditAddressModal';
import { EditNameModal } from 'components/modals/EditNameModal';
import { EditPhoneNumberModal } from 'components/modals/EditPhoneNumberModal';
import { Heading } from 'components/UI/Heading';
import { List } from 'components/UI/List';
import { ListItem } from 'components/UI/ListItem';
import { Section } from 'components/UI/Section';
import { useTranslations } from 'hooks/useTranslations';
import { getLanguageName, getUserAddress } from 'utils/userUtils';

export const ProfilePage: FC = () => {
  const t = useTranslations();

  const { countries, languages, translations, user } = useContext();

  const [editNameModal, setEditNameModal] = useState<boolean>(false);
  const [editAddressModal, setEditAddressModal] = useState<boolean>(false);
  const [editPhoneNumberModal, setEditPhoneNumberModal] =
    useState<boolean>(false);
  const [changeLanguageModal, setChangeLanguageModal] =
    useState<boolean>(false);

  const onEditNameOpen = useCallback(() => setEditNameModal(true), []);
  const onEditNameClose = useCallback(() => setEditNameModal(false), []);
  const onEditAddressOpen = useCallback(() => setEditAddressModal(true), []);
  const onEditAddressClose = useCallback(() => setEditAddressModal(false), []);
  const onEditPhoneNumberOpen = useCallback(
    () => setEditPhoneNumberModal(true),
    []
  );
  const onEditPhoneNumberClose = useCallback(
    () => setEditPhoneNumberModal(false),
    []
  );
  const onChangeLanguageOpen = useCallback(
    () => setChangeLanguageModal(true),
    []
  );
  const onChangeLanguageClose = useCallback(
    () => setChangeLanguageModal(false),
    []
  );

  return (
    <>
      <Heading>{t('profile.title')}</Heading>
      <Section>
        <Heading type="h2">{t('profile.heading_personal_information')}</Heading>
        <List>
          <ListItem
            headline={t('profile.action_name')}
            supportingText={`${user.firstName} ${user.lastName}`}
            onClick={onEditNameOpen}
          />
          <ListItem
            headline={t('profile.action_address')}
            supportingText={getUserAddress(user, countries)}
            onClick={onEditAddressOpen}
          />
          <ListItem
            headline={t('profile.action_phone_number')}
            supportingText={user.telephoneNumber}
            onClick={onEditPhoneNumberOpen}
          />
        </List>
      </Section>
      <Section>
        <Heading type="h2">{t('profile.heading_language')}</Heading>
        <List>
          <ListItem
            headline={t('profile.action_change_language')}
            supportingText={getLanguageName(user.language, languages)}
            onClick={onChangeLanguageOpen}
          />
        </List>
      </Section>
      <EditNameModal
        user={user}
        isOpen={editNameModal}
        close={onEditNameClose}
      />
      <EditAddressModal
        user={user}
        countries={countries}
        isOpen={editAddressModal}
        close={onEditAddressClose}
      />
      <EditPhoneNumberModal
        user={user}
        isOpen={editPhoneNumberModal}
        close={onEditPhoneNumberClose}
      />
      <ChangeLanguageModal
        user={user}
        languages={languages}
        supportedLanguages={translations}
        isOpen={changeLanguageModal}
        close={onChangeLanguageClose}
      />
    </>
  );
};
