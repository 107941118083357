import { FC, useMemo } from 'react';

import { Badge } from 'components/UI/Badge';
import { Group } from 'models/diam';
import { Container } from './styled';

type Props = {
  groups: Group[];
};

export const UserGroups: FC<Props> = ({ groups }) => {
  const groupBadges = useMemo(
    () =>
      groups.map((group) => (
        <Badge key={group.id} variant="light">
          {group.name}
        </Badge>
      )),
    [groups]
  );

  return <Container>{groupBadges}</Container>;
};
