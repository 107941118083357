import { FC } from 'react';

import { ThemeColor } from 'styles';
import { StyledPath, StyledSvg } from './styled';

const types = {
  arrow:
    'M12 8.67521L19.796 16.4712C19.9912 16.6664 20.3078 16.6664 20.5031 16.4712L21.546 15.4283C21.7412 15.233 21.7412 14.9164 21.546 14.7212L12.3536 5.52877C12.1583 5.3335 11.8417 5.3335 11.6465 5.52877L2.4541 14.7212C2.25884 14.9164 2.25884 15.233 2.4541 15.4283L3.49699 16.4712C3.69225 16.6664 4.00884 16.6664 4.2041 16.4712L12 8.67521Z',
  avatar:
    'M12 14C8.68625 14 5.99996 11.3137 5.99996 8C5.99996 4.68629 8.68625 2 12 2C15.3137 2 18 4.68629 18 8C18 11.3137 15.3137 14 12 14ZM12 12C14.2091 12 16 10.2091 16 8C16 5.79086 14.2091 4 12 4C9.79082 4 7.99996 5.79086 7.99996 8C7.99996 10.2091 9.79082 12 12 12ZM2.99996 17C4.91608 15.4671 7.99996 14 12 14C16 14 19 15.5 21 17C21.6086 18.2172 21.9394 19.7122 21.9924 21.4851C22.0006 21.7611 21.7836 21.9915 21.5075 21.9998L21.4926 22L2.50729 22C2.23117 22 2.00732 21.7761 2.00732 21.5L2.00755 21.4851C2.06055 19.7122 2.39135 18.2172 2.99996 17ZM4.61188 18.2846C4.40554 18.7875 4.24953 19.3588 4.14668 20L19.8533 20C19.7521 19.3695 19.5995 18.8064 19.3983 18.3096C17.3107 16.8563 14.8535 16 12 16C9.09598 16 6.5291 16.8839 4.61188 18.2846Z',
  check:
    'M12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23ZM12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21ZM17.1673 7.88909C17.3625 7.69383 17.6791 7.69382 17.8744 7.88909L18.5815 8.59619C18.7767 8.79146 18.7767 9.10804 18.5815 9.3033L11.5104 16.3744L10.8033 17.0815C10.608 17.2767 10.2915 17.2767 10.0962 17.0815L9.38909 16.3744L5.85355 12.8388C5.65829 12.6436 5.65829 12.327 5.85355 12.1317L6.56066 11.4246C6.75592 11.2294 7.0725 11.2294 7.26777 11.4246L10.4497 14.6066L17.1673 7.88909Z',
  email:
    'M22.5 4H1.5C1.22386 4 1 4.22386 1 4.5V20.5C1 20.7761 1.22386 21 1.5 21H22.5C22.7761 21 23 20.7761 23 20.5V4.5C23 4.22386 22.7761 4 22.5 4ZM21 6H3V19H21V6ZM3 8.39121V6L12 13.6088L21 6V8.39121L12 16L3 8.39121Z',
  error:
    'M23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12ZM3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12ZM13 6.5C13 6.22386 12.7761 6 12.5 6H11.5C11.2239 6 11 6.22386 11 6.5V13.5C11 13.7761 11.2239 14 11.5 14H12.5C12.7761 14 13 13.7761 13 13.5V6.5ZM12.5 16C12.7761 16 13 16.2239 13 16.5V17.5C13 17.7761 12.7761 18 12.5 18H11.5C11.2239 18 11 17.7761 11 17.5V16.5C11 16.2239 11.2239 16 11.5 16L12.5 16Z',
  exit: 'M5.99161 5.27555C5.79532 5.08133 5.47706 5.08133 5.28076 5.27555L4.5774 5.97149C4.3811 6.16572 4.3811 6.48062 4.5774 6.67484L10.5859 12.62L4.5774 18.5651C4.3811 18.7593 4.3811 19.0742 4.5774 19.2684L5.28076 19.9644C5.47706 20.1586 5.79532 20.1586 5.99161 19.9644L12.0001 14.0192L18.0087 19.9644C18.205 20.1586 18.5232 20.1586 18.7195 19.9644L19.4229 19.2684C19.6192 19.0742 19.6192 18.7593 19.4229 18.5651L13.4144 12.62L19.4229 6.67484C19.6192 6.48061 19.6192 6.16572 19.4229 5.97149L18.7195 5.27555C18.5232 5.08133 18.205 5.08133 18.0087 5.27555L12.0001 11.2207L5.99161 5.27555Z',
  globe:
    'M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM19.7393 14.3809C19.9707 13.628 20.0952 12.8283 20.0952 11.9995C20.0952 11.5124 20.0522 11.0352 19.9697 10.5718H16.0508C16.101 11.0338 16.1301 11.4965 16.1382 11.9599C16.1523 12.7668 16.1025 13.5738 15.9891 14.3809H19.7393ZM18.8688 16.2856H15.6034C15.2964 17.4465 14.8582 18.607 14.2895 19.7665C16.2084 19.2017 17.831 17.9453 18.8688 16.2856ZM9.69314 19.7613C9.10211 18.6059 8.64284 17.4471 8.31619 16.2856H5.13124C6.16591 17.9403 7.78186 19.1942 9.69314 19.7613ZM19.3797 8.66699C18.3734 6.44219 16.3906 4.75352 13.978 4.14767C14.7859 5.64486 15.3686 7.15176 15.7242 8.66699H19.3797ZM9.69314 4.23773C8.94048 5.70913 8.4015 7.18598 8.07796 8.66699H4.62035C5.58051 6.54413 7.42975 4.90941 9.69314 4.23773ZM4.03027 10.5718H7.78098C7.73654 11.0473 7.71429 11.5233 7.71429 11.9995C7.71429 12.7943 7.77624 13.5881 7.89989 14.3809H4.26068C4.0293 13.628 3.90476 12.8283 3.90476 11.9995C3.90476 11.5124 3.9478 11.0352 4.03027 10.5718ZM10.0339 8.66699C10.3979 7.20295 11.0021 5.73363 11.8489 4.25725C12.7316 5.72945 13.3679 7.19877 13.7605 8.66699H10.0339ZM9.69529 10.5718H14.1332C14.192 11.0456 14.2255 11.5194 14.2337 11.9932C14.2476 12.7866 14.1908 13.5824 14.0629 14.3809H9.831C9.68957 13.5857 9.61905 12.7921 9.61905 11.9995C9.61905 11.5241 9.64443 11.0481 9.69529 10.5718ZM10.3047 16.2856H13.6245C13.2542 17.5203 12.7152 18.7618 12.0062 20.0114C11.2666 18.7647 10.6999 17.5232 10.3047 16.2856Z',
  hamburger:
    'M20.5 18C20.7761 18 21 17.7761 21 17.5V16.5C21 16.2239 20.7761 16 20.5 16H3.5C3.22386 16 3 16.2239 3 16.5V17.5C3 17.7761 3.22386 18 3.5 18H20.5ZM20.5 13C20.7761 13 21 12.7761 21 12.5V11.5C21 11.2239 20.7761 11 20.5 11H3.5C3.22386 11 3 11.2239 3 11.5V12.5C3 12.7761 3.22386 13 3.5 13H20.5ZM3 7.5C3 7.77614 3.22386 8 3.5 8H20.5C20.7761 8 21 7.77614 21 7.5V6.5C21 6.22386 20.7761 6 20.5 6H3.5C3.22386 6 3 6.22386 3 6.5V7.5Z',
  hidePassword:
    'M7.01327 7.02665L3.59356 3.60693L2.17934 5.02115L5.12293 7.96474C3.89143 8.68905 2.71382 9.61251 1.59213 10.7332L1 11.3249L1.47815 12.0119C4.23537 15.9736 7.77119 17.9964 12.01 17.9964C12.98 17.9964 13.9229 17.8906 14.8381 17.6799L19.1499 21.9917L20.5641 20.5775L16.9663 16.9797C16.9679 16.979 16.9694 16.9784 16.971 16.9777L15.4262 15.4329C15.4245 15.4334 15.4229 15.434 15.4212 15.4346L14.2742 14.2876C14.2755 14.2867 14.2769 14.2858 14.2782 14.2849L8.71172 8.71841C8.7108 8.71972 8.70989 8.72104 8.70898 8.72236L8.57092 8.5843C8.5726 8.58375 8.57428 8.5832 8.57596 8.58265L7.01802 7.02471C7.01644 7.02536 7.01486 7.026 7.01327 7.02665ZM6.59127 9.43308C5.5664 9.98214 4.57794 10.6899 3.62418 11.5579C5.92668 14.5395 8.70164 15.9964 12.01 15.9964C12.378 15.9964 12.7414 15.9784 13.1004 15.9422L12.1519 14.9937C12.1015 14.9956 12.0509 14.9966 12 14.9966C9.79086 14.9966 8 13.2057 8 10.9966C8 10.9457 8.00095 10.8951 8.00283 10.8446L6.59127 9.43308ZM20.93 11.5183C19.8023 12.8038 18.6156 13.8089 17.3666 14.5382L18.8212 15.9928C20.3378 15.0278 21.7587 13.7168 23.0798 12.0653L23.6392 11.3661L23.0057 10.7332C19.8613 7.5915 16.2774 6 12.2989 6C11.2282 6 10.1861 6.11527 9.1734 6.34501L15.5903 12.762C15.8527 12.2295 16 11.6303 16 10.9966C16 9.95117 15.599 8.99943 14.9423 8.28682C17.0785 8.76374 19.0701 9.837 20.93 11.5183Z',
  lightbulb:
    'M5.41258 4.58747C7.39572 1.60421 11.0337 0.286368 14.3975 1.38153C17.7526 2.47388 20.0002 5.69318 20 9.29366C19.9987 11.1713 19.3796 12.9972 18.2388 14.4653L18.2318 14.4743C17.9343 14.8481 17.6593 15.1665 17.4258 15.4369C17.3672 15.5048 17.3112 15.5696 17.2581 15.6315C16.9844 15.9509 16.7924 16.1881 16.6443 16.4229C16.3861 16.8322 16.2109 17.3089 16.1977 18.3355V18.9999C16.1977 19.5522 15.7499 19.9999 15.1977 19.9999H8.79523C8.24294 19.9999 7.79523 19.5522 7.79523 18.9999V18.5544C7.78073 17.475 7.59836 16.9396 7.31903 16.4739C7.16325 16.2142 6.96216 15.9522 6.68338 15.6121C6.61275 15.5259 6.53671 15.4343 6.45614 15.3371C6.23019 15.0648 5.96852 14.7494 5.69016 14.3912L5.67906 14.3767C3.54213 11.5213 3.43578 7.56118 5.41258 4.58747ZM13 17.9999H14.2064C14.255 16.8723 14.492 16.0861 14.9528 15.3557C15.1855 14.9869 15.4618 14.6541 15.7396 14.3299C15.8002 14.2593 15.8613 14.1885 15.9231 14.1169C16.1533 13.8502 16.3946 13.5707 16.663 13.2336C17.5245 12.1229 17.999 10.7323 18 9.29292C17.9998 6.51385 16.2679 4.0938 13.7783 3.28328C11.2973 2.47551 8.58152 3.43313 7.07814 5.69466C5.57008 7.96326 5.65173 10.9959 7.27496 13.1712C7.52994 13.4989 7.75895 13.775 7.9779 14.0389C8.06319 14.1417 8.14695 14.2427 8.23012 14.3442C8.51834 14.6958 8.7985 15.0523 9.03412 15.4451C9.46743 16.1675 9.7062 16.9384 9.77503 17.9999H11V12.9999H10C9.44771 12.9999 9 12.5522 9 11.9999C9 11.4476 9.44771 10.9999 10 10.9999H14C14.5523 10.9999 15 11.4476 15 11.9999C15 12.5522 14.5523 12.9999 14 12.9999H13V17.9999ZM8 21.9999C8 21.4476 8.44771 20.9999 9 20.9999H15C15.5523 20.9999 16 21.4476 16 21.9999C16 22.5522 15.5523 22.9999 15 22.9999H9C8.44771 22.9999 8 22.5522 8 21.9999Z',
  link: 'M4.39816 18.5407C4.2029 18.3455 4.2029 18.0289 4.39816 17.8336L14.828 7.40381L5.42849 7.40381C5.15235 7.40381 4.92849 7.17995 4.92849 6.90381V5.42893C4.92849 5.15279 5.15235 4.92893 5.42849 4.92893H18.5706C18.8468 4.92893 19.0706 5.15279 19.0706 5.42893L19.0706 18.5711C19.0706 18.8472 18.8468 19.0711 18.5706 19.0711H17.0958C16.8196 19.0711 16.5958 18.8472 16.5958 18.5711V9.17157L6.16593 19.6014C5.97066 19.7967 5.65408 19.7967 5.45882 19.6014L4.39816 18.5407Z',
  logOut:
    'M17.5 7C17.7761 7 18 6.77614 18 6.5V3.5C18 3.22386 17.7761 3 17.5 3H6.5C6.22386 3 6 3.22386 6 3.5V20.5C6 20.7761 6.22386 21 6.5 21H17.5C17.7761 21 18 20.7761 18 20.5V17.5C18 17.2239 17.7761 17 17.5 17H16.5C16.2239 17 16 17.2239 16 17.5V19H8V5H16V6.5C16 6.77614 16.2239 7 16.5 7H17.5ZM11.5015 13H18V14C18 14.1082 18.0351 14.2135 18.1 14.3C18.2657 14.5209 18.5791 14.5657 18.8 14.4L21.4667 12.4C21.5046 12.3716 21.5382 12.3379 21.5667 12.3C21.7324 12.0791 21.6876 11.7657 21.4667 11.6L18.8 9.6C18.7135 9.53509 18.6082 9.5 18.5 9.5C18.2239 9.5 18 9.72386 18 10V11H11.5015C11.2245 11 11 11.214 11 11.5047V12.4953C11 12.774 11.2197 13 11.5015 13Z',
  shield:
    'M11.6489 1.06367C11.8753 0.978776 12.1247 0.978776 12.3511 1.06367L20.3511 4.06367C20.7414 4.21003 21 4.58316 21 5V12C21 15.4464 18.7183 18.2003 16.6585 20.0026C15.6076 20.9221 14.5615 21.6408 13.78 22.1292C13.3882 22.3741 13.0603 22.5627 12.8281 22.6913C12.7119 22.7556 12.6194 22.805 12.5547 22.839C12.5223 22.856 12.4968 22.8692 12.4788 22.8784L12.4573 22.8894L12.4508 22.8926L12.4486 22.8937C12.4486 22.8937 12.4472 22.8944 12 22C11.5528 22.8944 11.5522 22.8941 11.5522 22.8941L11.5492 22.8926L11.5427 22.8894L11.5212 22.8784C11.5032 22.8692 11.4777 22.856 11.4453 22.839C11.3806 22.805 11.2881 22.7556 11.1719 22.6913C10.9397 22.5627 10.6118 22.3741 10.22 22.1292C9.43854 21.6408 8.39238 20.9221 7.3415 20.0026C5.28175 18.2003 3 15.4464 3 12V5C3 4.58316 3.25857 4.21003 3.64888 4.06367L11.6489 1.06367ZM12 22L11.5522 22.8941C11.8337 23.0349 12.1657 23.0352 12.4472 22.8944L12 22ZM12 20.8628C12.1916 20.7541 12.4367 20.6103 12.72 20.4333C13.4385 19.9842 14.3924 19.3279 15.3415 18.4974C17.2817 16.7997 19 14.5536 19 12V5.693L12 3.068L5 5.693V12C5 14.5536 6.71825 16.7997 8.6585 18.4974C9.60762 19.3279 10.5615 19.9842 11.28 20.4333C11.5633 20.6103 11.8084 20.7541 12 20.8628Z',
  showPassword:
    'M1 11.3249L1.47815 12.0119C4.23537 15.9736 7.77119 17.9964 12.01 17.9964C16.229 17.9964 19.9367 15.9944 23.0798 12.0653L23.6392 11.3661L23.0057 10.7332C19.8613 7.5915 16.2774 6 12.2989 6C8.32048 6 4.73656 7.5915 1.59213 10.7332L1 11.3249ZM20.93 11.5183C18.2933 14.5239 15.3343 15.9964 12.01 15.9964C8.70164 15.9964 5.92668 14.5395 3.62418 11.5579C5.27256 10.0577 7.02456 9.03626 8.8891 8.48536C8.33306 9.17239 8 10.0473 8 11C8 13.2091 9.79086 15 12 15C14.2091 15 16 13.2091 16 11C16 9.95249 15.5973 8.99902 14.9384 8.28593C17.076 8.76241 19.069 9.83596 20.93 11.5183ZM11 11C11.5523 11 12 10.5523 12 10C12 9.44772 11.5523 9 11 9C10.4477 9 10 9.44772 10 10C10 10.5523 10.4477 11 11 11Z'
};

export type IconType = keyof typeof types;

const sizes = {
  small: 16,
  medium: 24,
  large: 36,
  extraLarge: 64
};

type IconSize = keyof typeof sizes;

const rotations = {
  up: '0',
  right: '90deg',
  down: '180deg',
  left: '270deg'
};

export type IconRotation = keyof typeof rotations;

type Props = {
  type: IconType;
  color?: ThemeColor;
  size?: IconSize;
  rotation?: IconRotation;
};

export const Icon: FC<Props> = ({
  type,
  color = 'onBackground',
  size = 'medium',
  rotation = 'up'
}) => (
  <StyledSvg
    width={sizes[size]}
    height={sizes[size]}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    $rotation={rotations[rotation]}
    data-testid="svg-icon"
  >
    <StyledPath
      fillRule="evenodd"
      clipRule="evenodd"
      d={types[type]}
      $color={color}
    />
  </StyledSvg>
);
