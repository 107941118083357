import styled from 'styled-components';

type Props = {
  $center?: boolean;
};

export const Description = styled.p<Props>`
  text-align: ${({ $center }) => ($center ? 'center' : 'start')};
  word-wrap: break-word;
  margin-bottom: 24px;
`;

export const IconContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 0 auto 24px;
`;

export const InputFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 32px;
`;
