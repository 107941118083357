import styled from 'styled-components';
import { motion } from 'framer-motion';
import { breakpoints } from 'styles';

export const ModalBackground = styled(motion.div)`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  justify-content: center;
  background-color: ${({ theme }) => `${theme.colors.dark}AA`};
  z-index: 999;
  overflow-y: auto;
`;

export const ModalContainer = styled(motion.div)`
  width: 100%;
  max-width: 550px;
  position: absolute;
  z-index: 999;

  @media only screen and (max-width: ${breakpoints.l}px) {
    max-width: none;
  }
`;

export const ModalBox = styled.div`
  background-color: ${({ theme }) => theme.colors.background};
  color: ${({ theme }) => theme.colors.onBackground};
  border-radius: 8px;
  margin: 25% 16px;

  @media (max-width: ${breakpoints.l}px) {
    min-height: 100vh;
    border-radius: 0;
    margin: 0;
  }
`;

export const ModalHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  h2 {
    flex: 1;
    font-size: 24px;
    line-height: 32px;
    margin: 32px 0 32px 32px;
  }
`;

export const ModalHeaderButton = styled.button`
  background: transparent;
  border: none;
  padding: 16px;
  margin: 16px;
  cursor: pointer;
`;

export const ModalContents = styled.div`
  padding: 0 32px 32px;
`;
