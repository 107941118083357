import styled from 'styled-components';

export const Container = styled.div`
  max-width: 500px;
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 96px auto;
`;

export const IconContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 32px auto;
`;

export const Description = styled.p`
  text-align: center;
  margin-bottom: 24px;
`;

export const ButtonGrid = styled.div`
  margin-top: 16px;
`;
