import { configureStore } from '@reduxjs/toolkit';

import { isDev, isLocal, isTest } from 'config';
import { rtkQueryError } from 'utils/fetchUtils/fetchUtils';
import { authReducer } from './authState';
import { diamApi } from './diamApi';
import { globalErrorReducer } from './globalErrorState';
import { localizationReducer } from './localizationState';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    globalError: globalErrorReducer,
    localization: localizationReducer,
    [diamApi.reducerPath]: diamApi.reducer
  },
  middleware: (getDefaultMiddleware) => {
    // Middlewares
    const middlewares = [diamApi.middleware];

    if (!isTest) {
      middlewares.push(rtkQueryError);
    }

    return getDefaultMiddleware().concat(middlewares);
  },
  devTools: isLocal || isDev
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
