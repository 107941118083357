import { FC, ReactNode } from 'react';
import { ThemeProvider, DefaultTheme } from 'styled-components';

import { GlobalStyles } from './globalStyles';
import { lightTheme } from './themes';
import './types';

export type ThemeColor = keyof DefaultTheme['colors'];

type Props = {
  children: ReactNode;
  theme?: DefaultTheme;
};

export const ThemeWrapper: FC<Props> = ({ children, theme = lightTheme }) => (
  <ThemeProvider theme={theme}>
    <GlobalStyles />
    {children}
  </ThemeProvider>
);

export * from './globalStyles';
export * from './themes';
