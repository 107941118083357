import styled from 'styled-components';

import { ThemeColor } from 'styles';

type StyledSvgProps = {
  $rotation: string;
};

type StyledPathProps = {
  $color: ThemeColor;
};

export const StyledSvg = styled.svg<StyledSvgProps>`
  transform: rotate(${({ $rotation }) => $rotation});
`;

export const StyledPath = styled.path<StyledPathProps>`
  fill: ${({ theme, $color }) => theme.colors[$color]};
`;
