import { motion } from 'framer-motion';
import styled from 'styled-components';

import { breakpoints } from 'styles';

export const PageContainer = styled.div`
  width: 100%;
  min-height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Header = styled.header`
  width: 100%;
  position: relative;
`;

export const HeaderLogo = styled.img`
  width: 84px;
  display: block;
  margin: 64px auto;

  @media only screen and (max-width: ${breakpoints.l}px) {
    margin: 48px auto;
  }
`;

export const MenuButtonContainer = styled.div`
  display: none;
  position: absolute;

  button {
    &:hover {
      background-color: transparent;
    }
  }

  @media only screen and (max-width: ${breakpoints.l}px) {
    display: flex;
  }
`;

export const ButtonContentContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
`;

export const BaseContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
`;

export const Sidebar = styled(motion.div)`
  width: 240px;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 64px 0 0;

  @media only screen and (max-width: ${breakpoints.l}px) {
    width: 100%;
    display: flex;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: ${({ theme }) => theme.colors.background};
    z-index: 100;
    padding: 0 16px 16px;
  }
`;

export const SidebarLogo = styled.img`
  width: 84px;
  display: none;
  margin: 48px auto;

  @media only screen and (max-width: ${breakpoints.l}px) {
    display: block;
  }
`;

export const Username = styled.strong`
  color: ${({ theme }) => theme.colors.brand};
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 16px;
`;

export const Description = styled.p`
  color: ${({ theme }) => theme.colors.onBackground};
  margin: 0 0 24px;
`;

export const Menu = styled.nav`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const MenuItemContentContainer = styled.span`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const MenuItemIconTitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  font-weight: 400;
`;

export const MenuItemArrowContainer = styled.div`
  display: none;

  @media only screen and (max-width: ${breakpoints.l}px) {
    display: block;
  }
`;

export const Content = styled.div`
  width: 100%;
  max-width: 656px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 0;

  @media only screen and (max-width: ${breakpoints.l}px) {
    max-width: ${breakpoints.l}px;
    margin: 0 16px;
  }
`;

export const Main = styled.main`
  width: 100%;
  margin: 0;
`;
