import { FC, useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { Loader } from 'components/UI/Loader';
import { useAppSelector } from 'hooks/redux';
import { authSelector } from 'store/authState';
import { useLoginWithValidateTokenMutation } from 'store/diamApi';
import * as routes from '../routes';

// Auth route
export const AuthRoute: FC = () => {
  const [login, result] = useLoginWithValidateTokenMutation();
  const { authenticated } = useAppSelector(authSelector);

  useEffect(() => {
    if (!authenticated) {
      login();
    }
  }, [authenticated, login]);

  if (!authenticated && (result.isUninitialized || result.isLoading)) {
    return <Loader />;
  }

  if (result.isError) {
    return <Navigate to={routes.LOGIN} />;
  }

  return <Outlet />;
};
