import { FC } from 'react';
import { Navigate } from 'react-router-dom';

import { Loader } from 'components/UI/Loader';
import { useQuery } from 'hooks/useQuery';
import * as routes from 'router/routes';
import { useLoginWithAuthorizationCodeQuery } from 'store/diamApi';

export const AuthorizePage: FC = () => {
  const [code] = useQuery('code', 'error');
  const login = useLoginWithAuthorizationCodeQuery(code ?? '');

  if (login.isLoading) {
    return <Loader />;
  }

  if (login.isError) {
    return <Navigate to={routes.LOGIN} replace />;
  }

  return <Navigate to={routes.PROFILE} replace />;
};
