import styled from 'styled-components';

export const SpinnerContainer = styled.div`
  width: 100%;
  padding: 32px;
`;

export const Spinner = styled.img`
  display: block;
  margin: 0 auto;
  animation: rotation 2s infinite linear;

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
`;
