import { FC } from 'react';
import { Navigate } from 'react-router-dom';

import { useAppSelector } from 'hooks/redux';
import { authSelector } from 'store/authState';
import * as routes from '../routes';

export const RootRoute: FC = () => {
  const { authenticated } = useAppSelector(authSelector);

  if (!authenticated) {
    return <Navigate to={routes.LOGIN} />;
  }

  return <Navigate to={routes.PROFILE} />;
};
