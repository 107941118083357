import { FC, useCallback } from 'react';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';

import { Button } from 'components/UI/Button';
import { InputField } from 'components/UI/InputField';
import { Modal } from 'components/UI/Modal';
import { useTranslations } from 'hooks/useTranslations';
import { ExtendedUser } from 'models/diam';
import { useUpdateUserMutation } from 'store/diamApi';
import { showSuccessToast } from 'utils/toastUtils';
import { InputFieldContainer } from './styled';

type Props = {
  user: ExtendedUser;
  isOpen: boolean;
  close: () => void;
};

export const EditNameModal: FC<Props> = ({ user, isOpen, close }) => {
  const t = useTranslations();
  const [updateUser] = useUpdateUserMutation();

  // useForm
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  // Close
  const onClose = useCallback(() => close(), [close]);

  // Submit
  const onSubmit: SubmitHandler<FieldValues> = useCallback(
    ({ firstName, lastName }) => {
      updateUser({
        firstName: firstName.trim(),
        lastName: lastName.trim()
      });
      showSuccessToast(t('profile.name_update_successful'));
      onClose();
    },
    [onClose, t, updateUser]
  );

  return (
    <Modal
      title={t('profile.action_edit_name')}
      isOpen={isOpen}
      onClose={onClose}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputFieldContainer>
          <InputField
            id="firstName"
            label={t('profile.label_first_name')}
            required
            defaultValue={user.firstName}
            placeholder={t('profile.placeholder_first_name')}
            error={errors.firstName}
            register={register('firstName', {
              required: {
                value: true,
                message: t('validation.required')
              }
            })}
          />
          <InputField
            id="lastName"
            label={t('profile.label_last_name')}
            required
            defaultValue={user.lastName}
            placeholder={t('profile.placeholder_last_name')}
            error={errors.lastName}
            register={register('lastName', {
              required: {
                value: true,
                message: t('validation.required')
              }
            })}
          />
        </InputFieldContainer>
        <Button type="submit">{t('profile.save')}</Button>
      </form>
    </Modal>
  );
};
