import { FC, useCallback, useEffect, useMemo } from 'react';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';

import { Button } from 'components/UI/Button';
import { InputField } from 'components/UI/InputField';
import { Modal } from 'components/UI/Modal';
import { UserGroups } from 'components/UI/UserGroups';
import { useTranslations } from 'hooks/useTranslations';
import { ExtendedUser } from 'models/diam';
import { useUpdatePasswordMutation } from 'store/diamApi';
import { defaultPasswordPolicy } from 'utils/constants';
import { getUserGroups } from 'utils/userUtils';
import { showSuccessToast } from 'utils/toastUtils';
import {
  Description,
  InputFieldContainer,
  UserGroupsContainer
} from './styled';

type Props = {
  user: ExtendedUser;
  isOpen: boolean;
  close: () => void;
};

export const ChangePasswordModal: FC<Props> = ({ user, isOpen, close }) => {
  const t = useTranslations();
  const [updatePassword, updatePasswordResult] = useUpdatePasswordMutation();

  // useForm
  const {
    register,
    getValues,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm();

  // Close
  const onClose = useCallback(() => {
    close();
    reset();
  }, [close, reset]);

  // Submit
  const onSubmit: SubmitHandler<FieldValues> = useCallback(
    ({ oldPassword, newPassword }) => {
      updatePassword({
        oldPassword,
        newPassword
      });
    },
    [updatePassword]
  );

  useEffect(() => {
    if (updatePasswordResult.isSuccess) {
      showSuccessToast(t('account_security.toast_password_change_successful'));
      onClose();
    }
  }, [onClose, t, updatePasswordResult.isSuccess]);

  const minimumLength = useMemo(
    () =>
      user.includedPasswordPolicy?.minimumLength ??
      defaultPasswordPolicy.minimumLength,
    [user.includedPasswordPolicy?.minimumLength]
  );

  const maximumLength = useMemo(
    () =>
      user.includedPasswordPolicy?.maximumLength ??
      defaultPasswordPolicy.maximumLength,
    [user.includedPasswordPolicy?.maximumLength]
  );

  return (
    <Modal
      title={t('account_security.action_change_password')}
      isOpen={isOpen}
      isLoading={updatePasswordResult.isLoading}
      onClose={onClose}
    >
      <Description>
        {t('account_security.description_change_password')}
      </Description>
      <Description>
        <strong>{user.username}</strong>
      </Description>
      <Description>{t('account_security.description_services')}</Description>
      <UserGroupsContainer>
        <UserGroups groups={getUserGroups(user)} />
      </UserGroupsContainer>
      <Description>
        {t('account_security.description_change_password_logout')}
      </Description>
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputFieldContainer>
          <InputField
            id="oldPassword"
            label={t('account_security.label_current_password')}
            required
            isPasswordInput
            placeholder={t('account_security.placeholder_current_password')}
            error={errors.oldPassword}
            register={register('oldPassword', {
              required: {
                value: true,
                message: t('validation.required')
              }
            })}
          />
          <InputField
            id="newPassword"
            label={t('account_security.label_new_password')}
            required
            isPasswordInput
            placeholder={t('account_security.placeholder_new_password')}
            error={errors.newPassword}
            register={register('newPassword', {
              required: {
                value: true,
                message: t('validation.required')
              },
              minLength: {
                value: minimumLength,
                message: t('validation.password_minimum_length', {
                  minimumLength
                })
              },
              maxLength: {
                value: maximumLength,
                message: t('validation.password_maximum_length', {
                  maximumLength
                })
              }
            })}
          />
          <InputField
            id="confirmPassword"
            label={t('account_security.label_confirm_password')}
            required
            isPasswordInput
            placeholder={t('account_security.placeholder_confirm_password')}
            error={errors.confirmPassword}
            register={register('confirmPassword', {
              required: {
                value: true,
                message: t('validation.required')
              },
              validate: (value) =>
                value === getValues('newPassword') ||
                t('validation.confirm_password')
            })}
          />
        </InputFieldContainer>
        <Button type="submit">{t('account_security.set_password')}</Button>
      </form>
    </Modal>
  );
};
