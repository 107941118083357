import { FC, useCallback, useMemo, useState } from 'react';

import { Button } from 'components/UI/Button';
import { Icon } from 'components/UI/Icon';
import { Modal } from 'components/UI/Modal';
import { UserGroups } from 'components/UI/UserGroups';
import { useTranslations } from 'hooks/useTranslations';
import { ExtendedUser } from 'models/diam';
import { useDeleteUserMutation } from 'store/diamApi';
import { getUserGroups } from 'utils/userUtils';
import { Description, IconContainer, UserGroupsContainer } from './styled';

type Props = {
  user: ExtendedUser;
  isOpen: boolean;
  close: () => void;
};

export const DeleteAccountModal: FC<Props> = ({ user, isOpen, close }) => {
  const t = useTranslations();
  const [startDelete, setStartDelete] = useState<boolean>(false);
  const [deleteUser, deleteUserResult] = useDeleteUserMutation();

  // Close
  const onClose = useCallback(() => {
    close();
    setStartDelete(false);
    deleteUserResult.reset();
  }, [close, deleteUserResult]);

  // Click
  const onStartDeleteClick = useCallback(() => setStartDelete(true), []);

  const onSendDeleteEmailClick = useCallback(() => deleteUser(), [deleteUser]);

  const content = useMemo(() => {
    if (deleteUserResult.isSuccess) {
      return (
        <>
          <IconContainer>
            <Icon type="email" color="brand" size="extraLarge" />
          </IconContainer>
          <Description $center>
            {t('account_security.description_delete_your_account_email')}
          </Description>
          <Description $center>
            <strong>{user.username}</strong>
          </Description>
        </>
      );
    }

    if (startDelete) {
      return (
        <>
          <Description>
            {t('account_security.description_delete_your_account_continue_1')}
          </Description>
          <Description>
            {t('account_security.description_delete_your_account_continue_2')}
          </Description>
          <Button
            type="button"
            onClick={onSendDeleteEmailClick}
            variant="danger"
          >
            {t('account_security.send_account_deletion_email')}
          </Button>
        </>
      );
    }

    return (
      <>
        <Description>
          {t('account_security.description_delete_your_account')}
        </Description>
        <UserGroupsContainer>
          <UserGroups groups={getUserGroups(user)} />
        </UserGroupsContainer>
        <Button type="button" onClick={onStartDeleteClick} variant="danger">
          {t('account_security.delete_account')}
        </Button>
      </>
    );
  }, [
    deleteUserResult.isSuccess,
    onSendDeleteEmailClick,
    onStartDeleteClick,
    startDelete,
    t,
    user
  ]);

  return (
    <Modal
      title={t('account_security.action_delete_your_account')}
      isOpen={isOpen}
      isLoading={deleteUserResult.isLoading}
      onClose={onClose}
    >
      {content}
    </Modal>
  );
};
