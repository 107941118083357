import { FC } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { ErrorBoundary } from 'components/fragments/ErrorBoundary';
import { PrivatePageWrapper } from 'components/fragments/PrivatePageWrapper';
import { PublicPageWrapper } from 'components/fragments/PublicPageWrapper';
import { AccountSecurityPage } from 'pages/AccountSecurityPage';
import { ActivateAccountPage } from 'pages/ActivateAccountPage';
import { AuthorizePage } from 'pages/AuthorizePage';
import { ChangeEmailPage } from 'pages/ChangeEmailPage';
import { ChangePasswordPage } from 'pages/ChangePasswordPage';
import { DeleteAccountPage } from 'pages/DeleteAccountPage';
import { LearnMorePage } from 'pages/LearnMorePage';
import { LoginPage } from 'pages/LoginPage';
import { NotFoundPage } from 'pages/NotFoundPage';
import { ProfilePage } from 'pages/ProfilePage';
import { ResendActivationPage } from 'pages/ResendActivationPage';
import { ResetPasswordPage } from 'pages/ResetPasswordPage';
import { SsoPage } from 'pages/SsoPage';
import { AuthRoute } from './AuthRoute';
import { RootRoute } from './RootRoute';
import * as routes from './routes';

// Router
const router = createBrowserRouter([
  {
    element: <ErrorBoundary />,
    children: [
      {
        element: <AuthRoute />,
        children: [
          {
            element: <PrivatePageWrapper />,
            children: [
              {
                path: routes.PROFILE,
                element: <ProfilePage />
              },
              {
                path: routes.ACCOUNT_SECURITY,
                element: <AccountSecurityPage />
              },
              {
                path: routes.LEARN_MORE,
                element: <LearnMorePage />
              }
            ]
          }
        ]
      },
      {
        element: <PublicPageWrapper />,
        children: [
          {
            path: routes.LOGIN,
            element: <LoginPage />
          },
          {
            path: routes.RESEND_ACTIVATION,
            element: <ResendActivationPage />
          },
          {
            path: routes.ACTIVATE_ACCOUNT,
            element: <ActivateAccountPage />
          },
          {
            path: routes.RESET_PASSWORD,
            element: <ResetPasswordPage />
          },
          {
            path: routes.CHANGE_PASSWORD,
            element: <ChangePasswordPage />
          },
          {
            path: routes.CHANGE_EMAIL,
            element: <ChangeEmailPage />
          },
          {
            path: routes.DELETE_ACCOUNT,
            element: <DeleteAccountPage />
          }
        ]
      },
      {
        path: routes.LOGIN_SSO,
        element: <SsoPage />
      },
      {
        path: routes.AUTHORIZE,
        element: <AuthorizePage />
      },
      {
        path: routes.ROOT,
        element: <RootRoute />
      },
      {
        path: '*',
        element: <NotFoundPage />
      }
    ]
  }
]);

export const Router: FC = () => <RouterProvider router={router} />;
