import { FC, useCallback } from 'react';

import gardenaLogo from 'assets/vectors/gardena-logo.svg';
import husqvarnaLogo from 'assets/vectors/husqvarna-logo.svg';
import { Icon } from 'components/UI/Icon';
import { Link } from 'components/UI/Link';
import { Modal } from 'components/UI/Modal';
import { useTranslations } from 'hooks/useTranslations';
import { gardenaSupportUrl, husqvarnaSupportUrl } from 'utils/constants';
import {
  BrandImage,
  Description,
  LinkContainer,
  LinkContentContainer
} from './styled';

type Props = {
  isOpen: boolean;
  close: () => void;
};

export const SupportModal: FC<Props> = ({ isOpen, close }) => {
  const t = useTranslations();

  // Close
  const onClose = useCallback(() => close(), [close]);

  return (
    <Modal
      title={t('learn_more.action_support')}
      isOpen={isOpen}
      onClose={onClose}
    >
      <Description>{t('learn_more.description_support')}</Description>
      <LinkContainer>
        <Link
          to={husqvarnaSupportUrl}
          target="_blank"
          rel="noopener noreferrer"
          variant="transparent"
        >
          <LinkContentContainer>
            <BrandImage src={husqvarnaLogo} alt="Husqvarna" />
            <Icon type="link" color="dark" />
          </LinkContentContainer>
        </Link>
        <Link
          to={gardenaSupportUrl}
          target="_blank"
          rel="noopener noreferrer"
          variant="transparent"
        >
          <LinkContentContainer>
            <BrandImage src={gardenaLogo} alt="GARDENA" />
            <Icon type="link" color="dark" />
          </LinkContentContainer>
        </Link>
      </LinkContainer>
    </Modal>
  );
};
