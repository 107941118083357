import {
  ReactNode,
  FC,
  useMemo,
  useCallback,
  MouseEventHandler,
  useEffect
} from 'react';

import { useKeyEffect } from 'hooks/useKeyEffect';
import { useTranslations } from 'hooks/useTranslations';
import { Heading } from 'components/UI/Heading';
import { Icon } from 'components/UI/Icon';
import { Loader } from 'components/UI/Loader';
import {
  ModalBackground,
  ModalBox,
  ModalContainer,
  ModalContents,
  ModalHeader,
  ModalHeaderButton
} from './styled';

type Props = {
  children?: ReactNode;
  title: string;
  isOpen: boolean;
  isLoading?: boolean;
  onClose: () => void;
};

export const Modal: FC<Props> = ({
  children,
  title,
  isOpen,
  isLoading = false,
  onClose
}) => {
  const t = useTranslations();

  // Close modal when pressing Escape
  useKeyEffect({
    action: onClose,
    addEventListener: isOpen,
    eventKeys: ['Escape'],
    target: window
  });

  // Freeze body if modal is open
  useEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : 'unset';
  }, [isOpen]);

  // Avoid close on modal click
  const onModalMouseDown: MouseEventHandler<HTMLDivElement> = useCallback(
    (e) => e.stopPropagation(),
    []
  );

  const content = useMemo(() => {
    if (isLoading) {
      return <Loader />;
    }

    return children;
  }, [children, isLoading]);

  return (
    <ModalBackground
      initial={false}
      transition={{ type: 'spring', stiffness: 100 }}
      variants={{
        open: {
          opacity: 1,
          display: 'flex',
          transition: {
            duration: 0.15
          }
        },
        closed: {
          opacity: 0,
          display: 'none',
          transition: {
            duration: 0.15,
            display: {
              delay: 0.1
            }
          }
        }
      }}
      animate={isOpen ? 'open' : 'closed'}
      onMouseDown={onClose}
    >
      <ModalContainer
        initial={false}
        transition={{ type: 'spring', stiffness: 100 }}
        variants={{
          open: {
            opacity: 1,
            display: 'block',
            transition: {
              duration: 0.15
            }
          },
          closed: {
            opacity: 0,
            display: 'none',
            transition: {
              duration: 0.15,
              display: {
                delay: 0.1
              }
            }
          }
        }}
        animate={isOpen ? 'open' : 'closed'}
      >
        <ModalBox onMouseDown={onModalMouseDown}>
          <ModalHeader>
            <Heading type="h2">{title}</Heading>
            <ModalHeaderButton aria-label={t('general.exit')} onClick={onClose}>
              <Icon type="exit" color="dark" />
            </ModalHeaderButton>
          </ModalHeader>
          <ModalContents>{content}</ModalContents>
        </ModalBox>
      </ModalContainer>
    </ModalBackground>
  );
};
