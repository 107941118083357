import { DefaultTheme } from 'styled-components';

export const lightTheme: DefaultTheme = {
  fontFamily: "'Open Sans', 'Arial', sans-serif;",
  colors: {
    background: '#FFFFFF',
    onBackground: '#716A5B',
    primary: '#002454',
    onPrimary: '#FFFFFF',
    brand: '#002454',
    brand110: '#001D43',
    brand90: '#00367F',
    brand5: '#F2F4F6',
    success: '#31B900',
    info: '#07AFDC',
    warning: '#FFC800',
    error: '#B10000',
    error110: '#9F0000',
    white: '#FFFFFF',
    subtle: '#8F8E8E',
    lightGray: '#D2D2D1',
    gray: '#3D3D3C',
    dark: '#0E1219',
    black: '#000000'
  }
};
