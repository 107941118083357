import errorCodes from 'assets/data/errorCodes.json';

export type ErrorCodeKey = keyof typeof errorCodes;

export const ErrorCodeTypes = {
  SUCCESS: 'success',
  INFO: 'info',
  WARNING: 'warning',
  ERROR: 'error',
  HIDDEN: 'hidden'
} as const;

export type ErrorCodeType =
  (typeof ErrorCodeTypes)[keyof typeof ErrorCodeTypes];

export type ErrorCode = {
  type: ErrorCodeType;
  messageKey?: string;
};

export type GlobalErrorState = {
  counter: number;
} & ErrorCode;
