import { createGlobalStyle } from 'styled-components';

// Breakpoints
export const breakpoints = {
  s: 576,
  m: 768,
  l: 992,
  xl: 1200,
  xxl: 1400
};

export const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  html, body {
    width: 100%;
    height: 100%;
    font-family: ${({ theme }) => theme.fontFamily};
    padding: 0;
    margin: 0;
  }

  #root {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
  }

  p,
  textarea,
  label,
  button {
    color: ${({ theme }) => theme.colors.onBackground};
    font-family: ${({ theme }) => theme.fontFamily};
  }

  strong {
    color: ${({ theme }) => theme.colors.dark};
    font-family: ${({ theme }) => theme.fontFamily};
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: ${({ theme }) => theme.fontFamily};
    font-weight: 600;
  }

  h1 {
      font-size: 28px;
      line-height: 32px;
      margin: 0 0 16px;
  }

  h2 {
      font-size: 20px;
      line-height: 24px;
      margin: 0 0 16px;
  }

  h3 {
      font-size: 20px;
      line-height: 24px;
      margin: 0 0 16px;
  }

  p {
    font-size: 16px;
    line-height: 20px;
    margin: 0;
  }
`;
