import { FC, MouseEvent } from 'react';

import { Heading } from 'components/UI/Heading';
import { Icon } from 'components/UI/Icon';
import { ListItemSupportingText, ListItemText, StyledListItem } from './styled';

type Props = {
  headline: string;
  supportingText?: string;
  onClick: (event: MouseEvent<HTMLLIElement>) => void;
};

export const ListItem: FC<Props> = ({ headline, supportingText, onClick }) => (
  <StyledListItem onClick={onClick}>
    <ListItemText>
      <Heading type="h3">{headline}</Heading>
      <ListItemSupportingText>{supportingText || '-'}</ListItemSupportingText>
    </ListItemText>
    <Icon type="arrow" color="dark" rotation="right" />
  </StyledListItem>
);
