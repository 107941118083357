import styled from 'styled-components';

export const Description = styled.p`
  margin-bottom: 24px;
`;

export const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const LinkContentContainer = styled.span`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const BrandImage = styled.img`
  height: 24px;
`;
