import { FC, useCallback, useMemo } from 'react';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';

import { Button } from 'components/UI/Button';
import { InputField } from 'components/UI/InputField';
import { Modal } from 'components/UI/Modal';
import { SelectField } from 'components/UI/SelectField';
import { useTranslations } from 'hooks/useTranslations';
import { Country, ExtendedUser } from 'models/diam';
import { useUpdateUserMutation } from 'store/diamApi';
import { showSuccessToast } from 'utils/toastUtils';
import { InputFieldContainer } from './styled';

type Props = {
  user: ExtendedUser;
  countries: Country[];
  isOpen: boolean;
  close: () => void;
};

export const EditAddressModal: FC<Props> = ({
  user,
  countries,
  isOpen,
  close
}) => {
  const t = useTranslations();
  const [updateUser] = useUpdateUserMutation();

  // useForm
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors }
  } = useForm();

  // Close
  const onClose = useCallback(() => close(), [close]);

  // Submit
  const onSubmit: SubmitHandler<FieldValues> = useCallback(
    ({ address, address2, city, postalCode, country }) => {
      updateUser({
        country,
        postalAddress: address.trim(),
        address2: address2.trim(),
        postalCode: postalCode.trim(),
        city: city.trim()
      });
      showSuccessToast(t('profile.address_update_successful'));
      onClose();
    },
    [onClose, t, updateUser]
  );

  // Setting form value for country
  const onSelectCountry = useCallback(
    (value: string) => {
      setValue('country', value);
    },
    [setValue]
  );

  const countryOptions = useMemo(
    () =>
      countries.map((country) => ({
        value: country.countryCode,
        label: country.name
      })),
    [countries]
  );

  return (
    <Modal
      title={t('profile.action_edit_address')}
      isOpen={isOpen}
      onClose={onClose}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputFieldContainer>
          <SelectField
            id="country"
            label={t('profile.label_country')}
            required
            enableSearch
            noMatchText={t('general.no_match_found')}
            defaultValue={user.country}
            placeholder={t('profile.placeholder_country')}
            error={errors.country}
            options={countryOptions}
            onSelectValue={onSelectCountry}
            register={register('country', {
              required: {
                value: true,
                message: t('validation.required')
              }
            })}
          />
          <InputField
            id="address"
            label={t('profile.label_address')}
            defaultValue={user.postalAddress}
            placeholder={t('profile.placeholder_address')}
            error={errors.address}
            register={register('address', {
              maxLength: {
                value: 100,
                message: t('validation.maximum_length', { length: 100 })
              }
            })}
          />
          <InputField
            id="address2"
            label={t('profile.label_address2')}
            defaultValue={user.address2}
            placeholder={t('profile.placeholder_address2')}
            error={errors.address2}
            register={register('address2', {
              maxLength: {
                value: 100,
                message: t('validation.maximum_length', { length: 100 })
              }
            })}
          />
          <InputField
            id="postalCode"
            label={t('profile.label_postal_code')}
            defaultValue={user.postalCode}
            placeholder={t('profile.placeholder_postal_code')}
            error={errors.postalCode}
            register={register('postalCode', {
              maxLength: {
                value: 50,
                message: t('validation.maximum_length', { length: 50 })
              }
            })}
          />
          <InputField
            id="city"
            label={t('profile.label_city')}
            defaultValue={user.city}
            placeholder={t('profile.placeholder_city')}
            error={errors.city}
            register={register('city', {
              maxLength: {
                value: 50,
                message: t('validation.maximum_length', { length: 50 })
              }
            })}
          />
        </InputFieldContainer>
        <Button type="submit">{t('profile.save')}</Button>
      </form>
    </Modal>
  );
};
