import { FC } from 'react';

import SpinnerIcon from 'assets/images/spinner-icon.png';
import { useTranslations } from 'hooks/useTranslations';
import { Spinner, SpinnerContainer } from './styled';

const sizes = {
  small: 16,
  medium: 24,
  large: 36,
  extraLarge: 64
};

type LoaderSize = keyof typeof sizes;

type Props = {
  size?: LoaderSize;
};

export const Loader: FC<Props> = ({ size = 'medium' }) => {
  const t = useTranslations();

  return (
    <SpinnerContainer>
      <Spinner
        src={SpinnerIcon}
        alt={t('general.loading')}
        width={sizes[size]}
        height={sizes[size]}
      />
    </SpinnerContainer>
  );
};
