import { FC, useEffect } from 'react';
import 'react-toastify/dist/ReactToastify.css';

import { useAppSelector } from 'hooks/redux';
import { useTranslations } from 'hooks/useTranslations';
import { useScreenSize } from 'hooks/useScreenSize';
import { ErrorCodeTypes } from 'models/errors';
import { globalErrorSelector } from 'store/globalErrorState';
import { showToast } from 'utils/toastUtils';

import { StyledToast } from './styled';

export const ErrorDisplay: FC = () => {
  const t = useTranslations();
  const { isMobile } = useScreenSize();
  const {
    counter,
    type,
    messageKey = ''
  } = useAppSelector(globalErrorSelector);

  useEffect(() => {
    if (counter && type !== ErrorCodeTypes.HIDDEN) {
      showToast(t(messageKey), type);
    }
  }, [counter, type, messageKey, t]);

  return (
    <StyledToast
      position={isMobile ? 'bottom-center' : 'top-center'}
      draggable={false}
      autoClose={1000000}
      hideProgressBar
      closeOnClick
    />
  );
};
