import { FC, ReactNode, createElement } from 'react';
import { useTheme } from 'styled-components';

import { ThemeColor } from 'styles';

export type HeadingType = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

type Props = {
  children: ReactNode;
  type?: HeadingType;
  color?: ThemeColor;
};

export const Heading: FC<Props> = ({
  children,
  type = 'h1',
  color = 'brand'
}) => {
  const theme = useTheme();

  return createElement(
    type,
    { style: { color: theme.colors[color] } },
    children
  );
};
