import { FC, ReactNode } from 'react';

import { StyledSection } from './styled';

type Props = {
  children: ReactNode;
};

export const Section: FC<Props> = ({ children }) => (
  <StyledSection>{children}</StyledSection>
);
