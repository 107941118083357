import { FC, useCallback, useMemo, useState } from 'react';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';

import { Button } from 'components/UI/Button';
import { Icon } from 'components/UI/Icon';
import { InputField } from 'components/UI/InputField';
import { Modal } from 'components/UI/Modal';
import { useTranslations } from 'hooks/useTranslations';
import { ExtendedUser } from 'models/diam';
import { useChangeUsernameMutation } from 'store/diamApi';
import { mailRegex } from 'utils/constants';
import { Description, IconContainer, InputFieldContainer } from './styled';

type Props = {
  user: ExtendedUser;
  isOpen: boolean;
  close: () => void;
};

export const ChangeEmailAddressModal: FC<Props> = ({ user, isOpen, close }) => {
  const t = useTranslations();
  const [newEmailAddress, setNewEmailAddress] = useState<string | null>(null);
  const [changeUsername, changeUsernameResult] = useChangeUsernameMutation();

  // useForm
  const {
    register,
    handleSubmit,
    resetField,
    formState: { errors }
  } = useForm();

  // Close
  const onClose = useCallback(() => {
    close();
    resetField('password');
    setNewEmailAddress(null);
    changeUsernameResult.reset();
  }, [resetField, changeUsernameResult, close]);

  // Submit
  const onSubmit: SubmitHandler<FieldValues> = useCallback(
    ({ newUsername, password }) => {
      changeUsername({
        newUsername,
        password
      });
      setNewEmailAddress(newUsername);
    },
    [changeUsername]
  );

  const content = useMemo(() => {
    if (changeUsernameResult.isSuccess) {
      return (
        <>
          <IconContainer>
            <Icon type="email" color="brand" size="extraLarge" />
          </IconContainer>
          <Description $center>
            {t('account_security.description_change_email_address_email')}
          </Description>
          <Description $center>
            <strong>{newEmailAddress}</strong>
          </Description>
        </>
      );
    }

    return (
      <>
        <Description>
          {t('account_security.description_change_email_address')}
        </Description>
        <form onSubmit={handleSubmit(onSubmit)}>
          <InputFieldContainer>
            <InputField
              id="newUsername"
              label={t('account_security.label_new_email_address')}
              required
              placeholder={t('account_security.placeholder_new_email_address')}
              error={errors.newUsername}
              register={register('newUsername', {
                required: {
                  value: true,
                  message: t('validation.required')
                },
                pattern: {
                  value: mailRegex,
                  message: t('validation.email_address')
                },
                validate: (value) =>
                  value !== user.username || t('validation.email_address_same')
              })}
            />
            <InputField
              id="password"
              label={t('account_security.label_password')}
              required
              isPasswordInput
              placeholder={t('account_security.placeholder_password')}
              error={errors.password}
              register={register('password', {
                required: {
                  value: true,
                  message: t('validation.required')
                }
              })}
            />
          </InputFieldContainer>
          <Button type="submit">
            {t('account_security.send_confirmation_email')}
          </Button>
        </form>
      </>
    );
  }, [
    changeUsernameResult.isSuccess,
    errors.newUsername,
    errors.password,
    handleSubmit,
    newEmailAddress,
    onSubmit,
    register,
    t,
    user.username
  ]);

  return (
    <Modal
      title={t('account_security.action_change_email_address')}
      isOpen={isOpen}
      isLoading={changeUsernameResult.isLoading}
      onClose={onClose}
    >
      {content}
    </Modal>
  );
};
