import { FC, useMemo } from 'react';

import { Badge } from 'components/UI/Badge';
import { useAppSelector } from 'hooks/redux';
import { useGetScopeTranslationsQuery } from 'store/diamApi';
import { localizationSelector } from 'store/localizationState';
import { defaultLanguageCode } from 'utils/constants';

type Props = {
  scopeId: string;
};

export const ScopeTranslation: FC<Props> = ({ scopeId }) => {
  const { languageCode } = useAppSelector(localizationSelector);
  const scopeTranslations = useGetScopeTranslationsQuery(scopeId);

  const scopeTranslation = useMemo(() => {
    const translation = scopeTranslations.data?.find(
      (scope) => scope.language === languageCode
    )?.translation;

    if (!translation) {
      return scopeTranslations.data?.find(
        (scope) => scope.language === defaultLanguageCode
      )?.translation;
    }

    return translation;
  }, [languageCode, scopeTranslations.data]);

  const translation = useMemo(() => {
    if (!scopeTranslation) {
      return scopeId;
    }

    return scopeTranslation;
  }, [scopeId, scopeTranslation]);

  return <Badge variant="light">{translation}</Badge>;
};
