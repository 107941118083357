import { Country, ExtendedUser, Language } from 'models/diam';

export function getFullName(firstName?: string, lastName?: string) {
  const nameElements = [firstName, lastName].filter((value) => !!value);

  return nameElements.length ? nameElements.join(' ') : '-';
}

export function getCountryName(countryCode?: string, countries?: Country[]) {
  if (!countryCode || !countries) {
    return '-';
  }

  return (
    countries.find((country) => country.countryCode === countryCode)?.name ??
    '-'
  );
}

export function getLanguageName(langCode?: string, languages?: Language[]) {
  if (!langCode || !languages) {
    return '-';
  }

  return (
    languages.find((language) => language.langCode === langCode)?.name ?? '-'
  );
}

export function getUserAddress(user?: ExtendedUser, countries?: Country[]) {
  if (!user || !countries) {
    return '-';
  }

  const addressElements = [
    user.postalAddress,
    user.address2,
    user.city,
    user.postalCode,
    getCountryName(user.country, countries)
  ].filter((value) => !!value);

  return addressElements.length ? addressElements.join(', ') : '-';
}

export function getUserGroups(user?: ExtendedUser) {
  if (!user?.includedGroups) {
    return [];
  }

  return [...user.includedGroups].sort((a, b) => a.name.localeCompare(b.name));
}
