import { FC, ReactNode } from 'react';

import { Icon } from 'components/UI/Icon';
import { Link } from 'components/UI/Link';
import { ForwardMeta } from 'models/diam';
import { ButtonLinkItem } from 'models/layout';
import { ButtonGrid, Container, Description, IconContainer } from './styled';

type Props = {
  message?: string;
  children?: ReactNode;
  forward?: ForwardMeta;
  link?: ButtonLinkItem;
};

export const ErrorState: FC<Props> = ({ message, children, forward, link }) => (
  <Container>
    <IconContainer>
      <Icon type="error" color="error" size="extraLarge" />
    </IconContainer>
    <Description $center>{message}</Description>
    <ButtonGrid>
      {forward && <Link to={forward.uri}>{forward.text}</Link>}
      {link && (
        <Link to={link.url} variant={forward ? 'secondary' : 'primary'}>
          {link.text}
        </Link>
      )}
      {children}
    </ButtonGrid>
  </Container>
);
