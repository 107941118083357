import {
  ChangeEvent,
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';

import { Icon } from 'components/UI/Icon';
import { useKeyEffect } from 'hooks/useKeyEffect';
import { useDetectOutsideClick } from 'hooks/useDetectOutsideClick';
import {
  Container,
  Mark,
  Message,
  Option,
  OptionContent,
  OptionText,
  Options,
  Required,
  SelectFieldButton,
  SelectFieldContainer,
  SelectFieldGroup,
  SelectFieldInput,
  SelectFieldLabel
} from './styled';

export type OptionType = {
  value: string;
  label: string;
  detail?: ReactNode;
};

type Props = {
  id: string;
  label: string;
  required?: boolean;
  defaultValue?: string;
  placeholder?: string;
  noMatchText?: string;
  disabled?: boolean;
  message?: string;
  error?: any;
  enableSearch?: boolean;
  options: OptionType[];
  onSelectValue?: (value: string) => void;
  register?: any;
};

export const SelectField: FC<Props> = ({
  id,
  label,
  required,
  defaultValue,
  placeholder,
  noMatchText = 'No match found',
  disabled = false,
  message,
  error,
  enableSearch = false,
  options,
  onSelectValue,
  register
}) => {
  // Refs
  const selectRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  // State
  const [highlighted, setHighlighted] = useState<OptionType | null>(null);
  const [selected, setSelected] = useState<OptionType | null>(null);
  const [searchState, setSearchState] = useState<string>('');

  // Hooks
  const [isOpen, setIsOpen] = useDetectOutsideClick([selectRef], false);

  // Actions
  const onOpen = useCallback(() => setIsOpen(true), [setIsOpen]);
  const onClose = useCallback(() => setIsOpen(false), [setIsOpen]);
  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) =>
      setSearchState(event.target.value.toLowerCase()),
    []
  );

  // Set default value after rendering component
  useEffect(() => {
    if (defaultValue && options?.length) {
      const defaultOption = options.find(({ value }) => value === defaultValue);

      if (defaultOption) {
        setSelected(defaultOption);
      }

      if (defaultOption?.value && onSelectValue) {
        onSelectValue(defaultOption.value);
      }
    }
  }, [defaultValue, options, onSelectValue]);

  // Set focus and search state when activated or deactivated
  useEffect(() => {
    if (!isOpen && inputRef.current) {
      setSearchState('');
      inputRef.current.blur();
    }

    if (isOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isOpen]);

  // List of options filtered by search term
  const list = useMemo(() => {
    if (!searchState) {
      return options;
    }

    return options.filter((option) => {
      const lowerCaseLabel = option.label.toLowerCase();
      return lowerCaseLabel.includes(searchState);
    });
  }, [options, searchState]);

  // Set selected option, call onSelectValue and close dropdown
  const onSelect = useCallback(
    (option: OptionType | null) => {
      if (!option) {
        return;
      }

      setSelected(option);

      if (onSelectValue) {
        onSelectValue(option.value);
      }

      onClose();
    },
    [onClose, onSelectValue]
  );

  // Highlight and scroll to the selected option's element
  const highlightOptionElement = useCallback(
    (highlightedOption: OptionType | null) => {
      const selectElement = selectRef.current;

      if (highlightedOption) {
        setHighlighted(highlightedOption);
        const highlightedElement = selectElement?.querySelector<HTMLElement>(
          `#${id}${highlightedOption.value}`
        );
        highlightedElement?.scrollIntoView({ block: 'nearest' });
      }
    },
    [id]
  );

  // Handle keypresses within dropdown element
  const handleEnter = useCallback(() => {
    onSelect(highlighted);
  }, [highlighted, onSelect]);

  const handleArrowDown = useCallback(
    (event: KeyboardEvent) => {
      event.preventDefault();
      const highlightedIndex = list.indexOf(highlighted as OptionType);
      const nextHighlightedIndex =
        highlightedIndex === list.length - 1
          ? list.length - 1
          : highlightedIndex + 1;
      const highlightedOption = list[nextHighlightedIndex];
      highlightOptionElement(highlightedOption);
    },
    [highlightOptionElement, highlighted, list]
  );

  const handleArrowUp = useCallback(
    (event: KeyboardEvent) => {
      event.preventDefault();
      const highlightedIndex = list.indexOf(highlighted as OptionType);
      const nextHighlightedIndex =
        highlightedIndex <= 0 ? 0 : highlightedIndex - 1;
      const highlightedOption = list[nextHighlightedIndex];
      highlightOptionElement(highlightedOption);
    },
    [highlightOptionElement, highlighted, list]
  );

  // Keyboard navigation within dropdown
  useKeyEffect({
    action: onClose,
    addEventListener: isOpen,
    eventKeys: ['Tab'],
    target: selectRef.current
  });

  useKeyEffect({
    action: handleEnter,
    addEventListener: isOpen,
    eventKeys: ['Enter'],
    target: selectRef.current
  });

  useKeyEffect({
    action: handleArrowDown,
    addEventListener: isOpen,
    eventKeys: ['ArrowDown'],
    target: selectRef.current
  });

  useKeyEffect({
    action: handleArrowUp,
    addEventListener: isOpen,
    eventKeys: ['ArrowUp'],
    target: selectRef.current
  });

  // Render option with highlight based on current search phrase
  const renderOptionLabel = useCallback(
    (optionLabel: string) => {
      if (!searchState) {
        return optionLabel;
      }

      const searchStringStartIndex = optionLabel
        .toLowerCase()
        .indexOf(searchState);
      const searchStringEndIndex = searchStringStartIndex + searchState.length;

      return (
        <>
          {optionLabel.substring(0, searchStringStartIndex)}
          <Mark>
            {optionLabel.substring(
              searchStringStartIndex,
              searchStringEndIndex
            )}
          </Mark>
          {optionLabel.substring(searchStringEndIndex)}
        </>
      );
    },
    [searchState]
  );

  // Render selectable options
  const optionsList = useMemo(() => {
    if (!list.length) {
      return <Option $highlight={false}>{noMatchText}</Option>;
    }

    return list.map((option) => (
      <Option
        key={option.value}
        id={`${id}${option.value}`}
        $highlight={highlighted?.value === option.value}
        onClick={() => onSelect(option)}
      >
        <OptionContent>
          <OptionText>{renderOptionLabel(option.label)}</OptionText>
          {option.detail ?? null}
        </OptionContent>
        {selected?.value === option.value && (
          <Icon type="check" color="onBackground" />
        )}
      </Option>
    ));
  }, [
    list,
    noMatchText,
    id,
    highlighted?.value,
    renderOptionLabel,
    selected?.value,
    onSelect
  ]);

  // Message
  const messageText = useMemo(() => {
    if (error && message) {
      return `${message} (${error.message})`;
    }

    if (error) {
      return error.message;
    }

    if (message) {
      return message;
    }

    return null;
  }, [message, error]);

  return (
    <Container>
      <SelectFieldLabel htmlFor={id} $error={error}>
        {label}
        {required && <Required>*</Required>}
        {messageText && <Message>{`- ${messageText}`}</Message>}
      </SelectFieldLabel>
      <SelectFieldContainer
        disabled={disabled}
        $isOpen={isOpen}
        $error={error}
        ref={selectRef}
      >
        <SelectFieldGroup $isOpen={isOpen} disabled={disabled}>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <input type="hidden" readOnly autoComplete="off" {...register} />
          <SelectFieldInput
            id={id}
            placeholder={placeholder}
            value={isOpen ? searchState : selected?.label ?? ''}
            onFocus={onOpen}
            onChange={onChange}
            disabled={disabled}
            readOnly={!enableSearch}
            autoComplete="off"
            ref={inputRef}
          />
          <SelectFieldButton
            type="button"
            disabled={disabled}
            onClick={isOpen ? onClose : onOpen}
            tabIndex={-1}
          >
            <Icon
              type="arrow"
              color="onBackground"
              rotation={isOpen ? 'up' : 'down'}
            />
          </SelectFieldButton>
        </SelectFieldGroup>
        <Options $isOpen={isOpen}>{optionsList}</Options>
      </SelectFieldContainer>
    </Container>
  );
};
