import {
  Dispatch,
  PayloadAction,
  createAction,
  createSlice,
  isAnyOf
} from '@reduxjs/toolkit';

import { AuthState } from 'models/auth';
import type { RootState } from 'store';
import { diamApi } from 'store/diamApi';
import * as localStorage from 'utils/localStorageUtils';
import * as loginUtils from 'utils/loginUtils';

export const defaultAuthState: AuthState = {
  accessToken: null,
  refreshToken: null,
  userId: null,
  authenticated: false,
  error: null
};

export const unauthorizedError = createAction('unauthorized');

const authSlice = createSlice({
  name: 'auth',
  initialState: defaultAuthState,
  reducers: {
    authError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    resetAuthError: (state) => {
      state.error = null;
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(
        diamApi.endpoints.loginWithAuthorizationCode.matchFulfilled,
        diamApi.endpoints.loginWithValidateToken.matchFulfilled
      ),
      (state, { payload }) => {
        const { accessToken, refreshToken, userId } = payload;

        localStorage.setItem('session', { accessToken, refreshToken, userId });

        state.accessToken = accessToken;
        state.refreshToken = refreshToken ?? null;
        state.userId = userId;
        state.authenticated = true;
      }
    );
    builder.addMatcher(
      isAnyOf(
        unauthorizedError,
        diamApi.endpoints.logout.matchFulfilled,
        diamApi.endpoints.confirmUserDeletion.matchFulfilled,
        diamApi.endpoints.loginWithAuthorizationCode.matchRejected,
        diamApi.endpoints.loginWithValidateToken.matchRejected
      ),
      (state) => {
        localStorage.removeItem('session');

        state.accessToken = defaultAuthState.accessToken;
        state.refreshToken = defaultAuthState.refreshToken;
        state.userId = defaultAuthState.userId;
        state.authenticated = false;
      }
    );
  }
});

// Export actions
export const { authError, resetAuthError } = authSlice.actions;

// Export reducer
export const authReducer = authSlice.reducer;

// Export selector
export const authSelector = (state: RootState) => state.auth;

// Export thunks
export function loginRedirect(languageCode: string) {
  return async (dispatch: Dispatch) => {
    try {
      const url = await loginUtils.getDiamLoginUrl(languageCode);

      window.location.assign(url);
    } catch (e: any) {
      dispatch(authError(e.message));
    }
  };
}

export function ssoRedirect(ssoToken: string, languageCode: string) {
  return async (dispatch: Dispatch) => {
    try {
      const url = await loginUtils.getDiamSsoUrl(ssoToken, languageCode);

      window.location.replace(url);
    } catch (e: any) {
      dispatch(authError(e.message));
    }
  };
}
