import { FC } from 'react';

import { Link } from 'components/UI/Link';
import { Icon } from 'components/UI/Icon';
import { useTranslations } from 'hooks/useTranslations';
import * as routes from 'router/routes';
import { Container, ContentContainer } from './styled';

export const BackButton: FC = () => {
  const t = useTranslations();

  return (
    <Container>
      <Link to={routes.LOGIN} variant="transparent">
        <ContentContainer>
          <Icon type="arrow" color="brand" rotation="left" />
          {t('general.back')}
        </ContentContainer>
      </Link>
    </Container>
  );
};
