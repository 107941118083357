import { FC } from 'react';

import { Heading } from 'components/UI/Heading';
import { Icon } from 'components/UI/Icon';
import { Link } from 'components/UI/Link';
import { useTranslations } from 'hooks/useTranslations';
import * as routes from 'router/routes';
import { Container, Description, IconContainer } from './styled';

export const NotFoundPage: FC = () => {
  const t = useTranslations();

  return (
    <Container>
      <IconContainer>
        <Icon type="error" color="error" size="extraLarge" />
      </IconContainer>
      <Heading>{t('not_found.title')}</Heading>
      <Description>{t('not_found.description')}</Description>
      <Link to={routes.PROFILE}>{t('not_found.button')}</Link>
    </Container>
  );
};
