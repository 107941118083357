import styled from 'styled-components';

export const Description = styled.p`
  margin-bottom: 24px;
`;

export const Category = styled.div`
  margin-bottom: 36px;
`;

export const Question = styled.p`
  color: ${({ theme }) => theme.colors.dark};
  font-weight: 600;
  margin-bottom: 16px;
`;

export const Answer = styled.p`
  margin-bottom: 24px;
`;
