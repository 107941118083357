import styled, { DefaultTheme } from 'styled-components';

type Props = {
  disabled?: boolean;
  $error?: boolean;
};

type StyleProps = Props & {
  theme: DefaultTheme;
};

// Handle background color
function handleBackgroundColor({ theme, disabled }: StyleProps) {
  if (disabled) {
    return theme.colors.lightGray;
  }

  return theme.colors.background;
}

// Handle text and border color
function handleColor({ theme, $error }: StyleProps) {
  if ($error) {
    return theme.colors.error;
  }

  return theme.colors.dark;
}

export const InputFieldContainer = styled.div`
  width: 100%;
  position: relative;
`;

export const InputFieldLabel = styled.label<Props>`
  display: inline-block;
  font-size: 16px;
  line-height: 24px;
  color: ${handleColor};
  margin-bottom: 4px;
`;

export const Required = styled.span`
  color: ${({ theme }) => theme.colors.error};
  margin-left: 4px;
`;

export const Message = styled.span`
  font-style: italic;
  margin-left: 4px;
`;

export const InputFieldInput = styled.input<Props>`
  width: 100%;
  min-width: 180px;
  min-height: 24px;
  background-color: ${handleBackgroundColor};
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.dark};
  caret-color: ${({ theme }) => theme.colors.brand90};
  padding: 16px;
  border: 1px solid ${handleColor};
  border-radius: 8px;
  outline: none;
  margin: 0;
`;

export const InputFieldButton = styled.button`
  position: absolute;
  right: 0;
  background: transparent;
  padding: 16px;
  border: none;
  cursor: pointer;
`;
