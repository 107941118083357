import { FC } from 'react';

import { Heading } from 'components/UI/Heading';
import { Icon } from 'components/UI/Icon';
import {
  ListItemSupportingText,
  ListItemText,
  StyledLink,
  StyledListItem
} from './styled';

type Props = {
  headline: string;
  supportingText: string;
  link: string;
};

export const ListItemLink: FC<Props> = ({ headline, supportingText, link }) => (
  <StyledListItem>
    <StyledLink to={link} target="_blank" rel="noopener noreferrer">
      <ListItemText>
        <Heading type="h3">{headline}</Heading>
        <ListItemSupportingText>{supportingText}</ListItemSupportingText>
      </ListItemText>
      <Icon type="link" color="dark" />
    </StyledLink>
  </StyledListItem>
);
