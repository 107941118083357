import { FC, useCallback, useMemo } from 'react';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';

import { Button } from 'components/UI/Button';
import { ErrorState } from 'components/UI/ErrorState';
import { Heading } from 'components/UI/Heading';
import { InputField } from 'components/UI/InputField';
import { SuccessState } from 'components/UI/SuccessState';
import { Loader } from 'components/UI/Loader';
import { UserGroups } from 'components/UI/UserGroups';
import { useQuery } from 'hooks/useQuery';
import { useTranslations } from 'hooks/useTranslations';
import * as routes from 'router/routes';
import {
  useConfirmUsernameChangeMutation,
  useValidateUsernameChangeCodeQuery
} from 'store/diamApi';
import { getUserGroups } from 'utils/userUtils';
import {
  Description,
  InputFieldContainer,
  UserGroupsContainer
} from './styled';

export const ChangeEmailPage: FC = () => {
  const t = useTranslations();
  const [code, uid] = useQuery('code', 'uid');
  const validateUsernameChangeCode = useValidateUsernameChangeCodeQuery({
    userId: uid ?? '',
    usernameChangeCode: code ?? ''
  });
  const [confirmUsernameChange, confirmUsernameChangeResult] =
    useConfirmUsernameChangeMutation();

  // useForm
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  // Submit
  const onSubmit: SubmitHandler<FieldValues> = useCallback(
    ({ password }) => {
      confirmUsernameChange({
        userId: uid ?? '',
        usernameChangeCode: code ?? '',
        password
      });
    },
    [code, confirmUsernameChange, uid]
  );

  const emailChangedAlready = useMemo(
    () =>
      validateUsernameChangeCode.data?.username ===
      validateUsernameChangeCode.data?.meta.new_username,
    [
      validateUsernameChangeCode.data?.meta.new_username,
      validateUsernameChangeCode.data?.username
    ]
  );

  const errorMessage = useMemo(
    () =>
      validateUsernameChangeCode.error?.code ===
      'username.change.request.expired'
        ? 'change_email.link_expired'
        : 'change_email.link_invalid',
    [validateUsernameChangeCode.error?.code]
  );

  if (
    validateUsernameChangeCode.isLoading ||
    confirmUsernameChangeResult.isLoading
  ) {
    return <Loader />;
  }

  if (
    !validateUsernameChangeCode.data ||
    validateUsernameChangeCode.isError ||
    emailChangedAlready
  ) {
    return (
      <ErrorState
        message={t(errorMessage)}
        link={{ url: routes.LOGIN, text: t('change_email.request_new_link') }}
      />
    );
  }

  if (confirmUsernameChangeResult.isSuccess) {
    const { forward } = validateUsernameChangeCode.data.meta;

    return (
      <SuccessState
        heading={t('change_email.title_success')}
        description={t('change_email.description_success')}
        forward={forward}
        link={{ url: routes.LOGIN, text: t('general.go_to_login') }}
      />
    );
  }

  return (
    <>
      <Heading>{t('change_email.title')}</Heading>
      <Description>{t('change_email.description_change_email')}</Description>
      <Description>
        <strong>{validateUsernameChangeCode.data.username}</strong>
      </Description>
      <Description>
        {t('change_email.description_change_email_services')}
      </Description>
      <UserGroupsContainer>
        <UserGroups groups={getUserGroups(validateUsernameChangeCode.data)} />
      </UserGroupsContainer>
      <Description>
        {t('change_email.description_change_email_enter_password')}
      </Description>
      <Description>
        <strong>{validateUsernameChangeCode.data.meta.new_username}</strong>
      </Description>
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputFieldContainer>
          <InputField
            id="password"
            label={t('account_security.label_password')}
            required
            isPasswordInput
            placeholder={t('account_security.placeholder_password')}
            error={errors.password}
            register={register('password', {
              required: {
                value: true,
                message: t('validation.required')
              }
            })}
          />
        </InputFieldContainer>
        <Button type="submit">{t('change_email.change_email_address')}</Button>
      </form>
    </>
  );
};
