import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Outlet, useLocation, useOutletContext } from 'react-router-dom';

import husqvarnaGroupLogo from 'assets/vectors/husqvarna-group-logo.svg';
import { Button } from 'components/UI/Button';
import { ErrorState } from 'components/UI/ErrorState';
import { Footer } from 'components/UI/Footer';
import { Icon } from 'components/UI/Icon';
import { LanguageSelector } from 'components/UI/LanguageSelector';
import { Link } from 'components/UI/Link';
import { Loader } from 'components/UI/Loader';
import { useTranslations } from 'hooks/useTranslations';
import { Country, ExtendedUser, Language } from 'models/diam';
import {
  useGetCountriesQuery,
  useGetLanguagesQuery,
  useGetTranslationsQuery,
  useGetUserQuery,
  useLogoutMutation
} from 'store/diamApi';
import { menuLinks } from 'utils/constants';
import { getFullName } from 'utils/userUtils';

import {
  BaseContainer,
  ButtonContentContainer,
  Content,
  Description,
  Header,
  HeaderLogo,
  Main,
  Menu,
  MenuButtonContainer,
  MenuItemArrowContainer,
  MenuItemContentContainer,
  MenuItemIconTitleContainer,
  PageContainer,
  Sidebar,
  SidebarLogo,
  Username
} from './styled';

export const PrivatePageWrapper: FC = () => {
  const t = useTranslations();
  const { pathname } = useLocation();
  const countries = useGetCountriesQuery();
  const languages = useGetLanguagesQuery();
  const translations = useGetTranslationsQuery();
  const user = useGetUserQuery();
  const [logout] = useLogoutMutation();
  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);

  const onOpenMenu = useCallback(() => setMenuIsOpen(true), []);
  const onCloseMenu = useCallback(() => setMenuIsOpen(false), []);
  const onLogoutClick = useCallback(() => logout(), [logout]);

  const menu = useMemo(
    () => (
      <>
        {menuLinks.map((menuLink) => {
          const isActive = menuLink.link === pathname;

          return (
            <Link
              key={menuLink.id}
              to={menuLink.link}
              variant={isActive ? 'primary' : 'transparent'}
              onClick={onCloseMenu}
            >
              <MenuItemContentContainer>
                <MenuItemIconTitleContainer>
                  <Icon
                    type={menuLink.icon}
                    color={isActive ? 'onPrimary' : 'brand'}
                  />
                  {t(menuLink.name)}
                </MenuItemIconTitleContainer>
                <MenuItemArrowContainer>
                  <Icon
                    type="arrow"
                    color={isActive ? 'onPrimary' : 'brand'}
                    rotation="right"
                  />
                </MenuItemArrowContainer>
              </MenuItemContentContainer>
            </Link>
          );
        })}
      </>
    ),
    [onCloseMenu, pathname, t]
  );

  // Scroll to top
  useEffect(() => window.scrollTo(0, 0), [pathname]);

  if (countries.isLoading || languages.isLoading || user.isLoading) {
    return <Loader />;
  }

  if (
    !countries.data ||
    !languages.data ||
    !translations.data ||
    !user.data ||
    countries.isError ||
    languages.isError ||
    translations.isError ||
    user.isError
  ) {
    return <ErrorState message={t('errors.unknown_error')} />;
  }

  return (
    <>
      <PageContainer>
        <Header>
          <MenuButtonContainer>
            <Button onClick={onOpenMenu} variant="transparent">
              <ButtonContentContainer>
                <Icon type="hamburger" color="brand" />
                {t('menu.menu')}
              </ButtonContentContainer>
            </Button>
          </MenuButtonContainer>
          <HeaderLogo src={husqvarnaGroupLogo} alt="Husqvarna Group" />
          <LanguageSelector />
        </Header>
        <BaseContainer>
          <Sidebar
            initial={false}
            transition={{ type: 'spring', stiffness: 100 }}
            variants={{
              open: {
                left: 0,
                transition: {
                  duration: 0.15
                }
              },
              closed: {
                left: '-100%',
                transition: {
                  duration: 0.15
                }
              }
            }}
            animate={menuIsOpen ? 'open' : 'closed'}
          >
            <SidebarLogo src={husqvarnaGroupLogo} alt="Husqvarna Group" />
            <Username>
              {getFullName(user.data?.firstName, user.data?.lastName)}
            </Username>
            <Description>{t('menu.description')}</Description>
            <Menu>
              {menu}
              <Button onClick={onLogoutClick} variant="transparent">
                <MenuItemContentContainer>
                  <MenuItemIconTitleContainer>
                    <Icon type="logOut" color="brand" />
                    {t('menu.log_out')}
                  </MenuItemIconTitleContainer>
                  <MenuItemArrowContainer>
                    <Icon type="arrow" color="brand" rotation="right" />
                  </MenuItemArrowContainer>
                </MenuItemContentContainer>
              </Button>
            </Menu>
          </Sidebar>
          <Content>
            <Main>
              <Outlet
                context={{
                  countries: countries.data,
                  languages: languages.data,
                  translations: translations.data,
                  user: user.data
                }}
              />
            </Main>
          </Content>
        </BaseContainer>
      </PageContainer>
      <Footer />
    </>
  );
};

type ContextType = {
  countries: Country[];
  languages: Language[];
  translations: Language[];
  user: ExtendedUser;
};

export function useContext() {
  return useOutletContext<ContextType>();
}
