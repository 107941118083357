import { createApi, fakeBaseQuery } from '@reduxjs/toolkit/query/react';

import {
  ActiveSession,
  Country,
  ExtendedUser,
  Language,
  ResendActivation,
  ScopeTranslation,
  Token,
  User,
  UserGrant
} from 'models/diam';
import {
  ActivateUserParams,
  ChangeUsernameParams,
  ConfirmUserDeletionParams,
  ConfirmUsernameChangeParams,
  SetNewPasswordParams,
  UpdatePasswordParams,
  ValidatePasswordRecoveryCodeParams,
  ValidateUserDeletionCodeParams,
  ValidateUsernameChangeCodeParams
} from 'models/diamApi';
import { JsonApiError } from 'models/jsonApiError';
import * as diamService from 'services/diamService';
import { getToken, queryFnWrapper } from 'utils/fetchUtils';

export const diamApi = createApi({
  reducerPath: 'diamApi',
  baseQuery: fakeBaseQuery<JsonApiError>(),
  keepUnusedDataFor: 3600,
  tagTypes: ['ActiveSessions', 'User', 'UserGrants'],
  endpoints: (builder) => ({
    // Account
    resendActivation: builder.mutation<ResendActivation, string>({
      async queryFn(email) {
        return queryFnWrapper(diamService.resendActivation(email));
      }
    }),
    activateUser: builder.query<ExtendedUser, ActivateUserParams>({
      async queryFn({ userId, activationCode }) {
        return queryFnWrapper(diamService.activateUser(userId, activationCode));
      }
    }),
    recoverPassword: builder.mutation<void, string>({
      async queryFn(email) {
        return queryFnWrapper(diamService.recoverPassword(email));
      }
    }),
    validatePasswordRecoveryCode: builder.query<
      ExtendedUser,
      ValidatePasswordRecoveryCodeParams
    >({
      async queryFn({ userId, recoveryCode }) {
        return queryFnWrapper(
          diamService.validatePasswordRecoveryCode(userId, recoveryCode)
        );
      }
    }),
    setNewPassword: builder.mutation<void, SetNewPasswordParams>({
      async queryFn({ userId, recoveryCode, newPassword }) {
        return queryFnWrapper(
          diamService.setNewPassword(userId, recoveryCode, newPassword)
        );
      }
    }),
    updatePassword: builder.mutation<void, UpdatePasswordParams>({
      async queryFn({ oldPassword, newPassword }, api) {
        return queryFnWrapper(
          diamService.updatePassword(getToken(api), oldPassword, newPassword)
        );
      }
    }),
    changeUsername: builder.mutation<void, ChangeUsernameParams>({
      async queryFn({ newUsername, password }, api) {
        return queryFnWrapper(
          diamService.changeUsername(getToken(api), newUsername, password)
        );
      }
    }),
    validateUsernameChangeCode: builder.query<
      ExtendedUser,
      ValidateUsernameChangeCodeParams
    >({
      async queryFn({ userId, usernameChangeCode }) {
        return queryFnWrapper(
          diamService.validateUsernameChangeCode(userId, usernameChangeCode)
        );
      }
    }),
    confirmUsernameChange: builder.mutation<
      ExtendedUser,
      ConfirmUsernameChangeParams
    >({
      async queryFn({ userId, usernameChangeCode, password }) {
        return queryFnWrapper(
          diamService.confirmUsernameChange(
            userId,
            usernameChangeCode,
            password
          )
        );
      }
    }),
    deleteUser: builder.mutation<void, void>({
      async queryFn(_arg, api) {
        return queryFnWrapper(diamService.deleteUser(getToken(api)));
      }
    }),
    validateUserDeletionCode: builder.query<
      ExtendedUser,
      ValidateUserDeletionCodeParams
    >({
      async queryFn({ userId, deletionCode }) {
        return queryFnWrapper(
          diamService.validateUserDeletionCode(userId, deletionCode)
        );
      }
    }),
    confirmUserDeletion: builder.mutation<void, ConfirmUserDeletionParams>({
      async queryFn({ userId, deletionCode, password }) {
        return queryFnWrapper(
          diamService.confirmUserDeletion(userId, deletionCode, password)
        );
      }
    }),
    // Basic data
    getLanguages: builder.query<Language[], void>({
      async queryFn() {
        return queryFnWrapper(diamService.getLanguages());
      }
    }),
    getTranslations: builder.query<Language[], void>({
      async queryFn() {
        return queryFnWrapper(diamService.getTranslations());
      }
    }),
    getCountries: builder.query<Country[], void>({
      async queryFn() {
        return queryFnWrapper(diamService.getCountries());
      }
    }),
    getScopeTranslations: builder.query<ScopeTranslation[], string>({
      async queryFn(scopeId, api) {
        return queryFnWrapper(
          diamService.getScopeTranslations(getToken(api), scopeId)
        );
      }
    }),
    // Token
    loginWithAuthorizationCode: builder.query<Token, string>({
      async queryFn(code) {
        return queryFnWrapper(diamService.loginWithAuthorizationCode(code));
      }
    }),
    loginWithValidateToken: builder.mutation<Token, void>({
      async queryFn() {
        return queryFnWrapper(diamService.loginWithValidateToken());
      }
    }),
    logout: builder.mutation<void, void>({
      async queryFn(_arg, api) {
        return queryFnWrapper(diamService.logout(getToken(api)));
      }
    }),
    logoutEverywhere: builder.mutation<void, void>({
      async queryFn(_arg, api) {
        return queryFnWrapper(diamService.logoutEverywhere(getToken(api)));
      },
      invalidatesTags: ['ActiveSessions']
    }),
    // User
    getUser: builder.query<ExtendedUser, void>({
      async queryFn(_arg, api) {
        return queryFnWrapper(diamService.getUser(getToken(api)));
      },
      providesTags: ['User']
    }),
    updateUser: builder.mutation<User, Partial<User>>({
      async queryFn(user, api) {
        return queryFnWrapper(diamService.updateUser(getToken(api), user));
      },
      async onQueryStarted(user, api) {
        // Optimistic update
        api.dispatch(
          diamApi.util.updateQueryData('getUser', undefined, (draft) => {
            Object.assign(draft, user);
          })
        );

        try {
          await api.queryFulfilled;
        } catch {
          api.dispatch(diamApi.util.invalidateTags(['User']));
        }
      }
    }),
    getUserGrants: builder.query<UserGrant[], void>({
      async queryFn(_arg, api) {
        return queryFnWrapper(diamService.getUserGrants(getToken(api)));
      },
      providesTags: ['UserGrants']
    }),
    getActiveSessions: builder.query<ActiveSession[], void>({
      async queryFn(_arg, api) {
        return queryFnWrapper(diamService.getActiveSessions(getToken(api)));
      },
      providesTags: ['ActiveSessions']
    })
  })
});

export const {
  useResendActivationMutation,
  useActivateUserQuery,
  useRecoverPasswordMutation,
  useValidatePasswordRecoveryCodeQuery,
  useSetNewPasswordMutation,
  useUpdatePasswordMutation,
  useChangeUsernameMutation,
  useValidateUsernameChangeCodeQuery,
  useConfirmUsernameChangeMutation,
  useDeleteUserMutation,
  useValidateUserDeletionCodeQuery,
  useConfirmUserDeletionMutation,
  useGetLanguagesQuery,
  useGetTranslationsQuery,
  useGetCountriesQuery,
  useGetScopeTranslationsQuery,
  useLoginWithAuthorizationCodeQuery,
  useLoginWithValidateTokenMutation,
  useLogoutMutation,
  useLogoutEverywhereMutation,
  useGetUserQuery,
  useUpdateUserMutation,
  useGetUserGrantsQuery,
  useGetActiveSessionsQuery
} = diamApi;
