import { FC, ReactNode } from 'react';

import { Container } from './styled';

type BadgeVariant = 'dark' | 'light';

type Props = {
  children: ReactNode;
  variant?: BadgeVariant;
};

export const Badge: FC<Props> = ({ children, variant = 'dark' }) => (
  <Container $variant={variant}>{children}</Container>
);
