import { FC, useCallback, useMemo } from 'react';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';

import { Badge } from 'components/UI/Badge';
import { Button } from 'components/UI/Button';
import { Modal } from 'components/UI/Modal';
import { SelectField } from 'components/UI/SelectField';
import { useTranslations } from 'hooks/useTranslations';
import { ExtendedUser, Language } from 'models/diam';
import { useUpdateUserMutation } from 'store/diamApi';
import { showSuccessToast } from 'utils/toastUtils';
import { Description, InputFieldContainer } from './styled';

type Props = {
  user: ExtendedUser;
  languages: Language[];
  supportedLanguages: Language[];
  isOpen: boolean;
  close: () => void;
};

export const ChangeLanguageModal: FC<Props> = ({
  user,
  languages,
  supportedLanguages,
  isOpen,
  close
}) => {
  const t = useTranslations();
  const [updateUser] = useUpdateUserMutation();

  // useForm
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors }
  } = useForm();

  // Close
  const onClose = useCallback(() => close(), [close]);

  // Submit
  const onSubmit: SubmitHandler<FieldValues> = useCallback(
    ({ language }) => {
      updateUser({
        language
      });
      showSuccessToast(t('profile.language_update_successful'));
      onClose();
    },
    [onClose, t, updateUser]
  );

  // Setting form value for language
  const onSelectLanguage = useCallback(
    (value: string) => {
      setValue('language', value);
    },
    [setValue]
  );

  const supportedBadge = useMemo(
    () => <Badge>{t('profile.supported')}</Badge>,
    [t]
  );

  const languageOptions = useMemo(() => {
    const supportedLangCodes = supportedLanguages.map(
      (supportedLang) => supportedLang.langCode
    );

    return languages.map((language) => {
      const supported = supportedLangCodes.includes(language.langCode);

      return {
        value: language.langCode,
        label: language.name,
        detail: supported ? supportedBadge : null
      };
    });
  }, [languages, supportedBadge, supportedLanguages]);

  return (
    <Modal
      title={t('profile.action_change_language')}
      isOpen={isOpen}
      onClose={onClose}
    >
      <Description>{t('profile.description_change_language')}</Description>
      <Description>{supportedBadge}</Description>
      <Description>
        {t('profile.description_change_language_applications')}
      </Description>
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputFieldContainer>
          <SelectField
            id="language"
            label={t('profile.label_language')}
            required
            enableSearch
            noMatchText={t('general.no_match_found')}
            defaultValue={user.language}
            placeholder={t('profile.placeholder_language')}
            error={errors.language}
            options={languageOptions}
            onSelectValue={onSelectLanguage}
            register={register('language', {
              required: {
                value: true,
                message: t('validation.required')
              }
            })}
          />
        </InputFieldContainer>
        <Button type="submit">{t('profile.set_language')}</Button>
      </form>
    </Modal>
  );
};
