import { FaqItem } from 'models/faq';
import { MenuLinkItem } from 'models/layout';
import * as routes from 'router/routes';

export const defaultLanguageCode = 'en';

export const privacyPolicyUrl = 'https://www.husqvarna.com/privacy';

export const husqvarnaSupportUrl = 'https://www.husqvarna.com/contact';

export const gardenaSupportUrl = 'https://www.gardena.com/contact';

export const mailRegex =
  /^[-!#$%&'*+/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-?\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;

export const defaultPasswordPolicy = {
  minimumLength: 6,
  maximumLength: 256
};

export const menuLinks: MenuLinkItem[] = [
  {
    id: 0,
    name: 'menu.profile',
    icon: 'avatar',
    link: routes.PROFILE
  },
  {
    id: 1,
    name: 'menu.account_security',
    icon: 'shield',
    link: routes.ACCOUNT_SECURITY
  },
  {
    id: 2,
    name: 'menu.learn_more',
    icon: 'lightbulb',
    link: routes.LEARN_MORE
  }
];

export const footerLinks = [
  {
    name: 'footer.privacy_policy',
    link: privacyPolicyUrl
  },
  {
    name: 'footer.terms_of_use',
    link: 'https://www.husqvarnagroup.com/en/terms-use'
  },
  {
    name: 'footer.cookies',
    link: 'https://www.husqvarnagroup.com/en/cookie-policy'
  }
];

export const faqItems: FaqItem[] = [
  {
    category: 'basics',
    questions: ['what_is', 'services']
  },
  {
    category: 'update',
    questions: ['update_from_service', 'password_email']
  },
  {
    category: 'delete',
    questions: ['leave', 'how_to']
  }
];
