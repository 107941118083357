import styled from 'styled-components';

type Props = {
  $isActive: boolean;
};

export const MenuContainer = styled.div`
  position: relative;
`;

export const MenuButton = styled.button`
  height: 56px;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.brand};
  font-size: 16px;
  line-height: 16px;
  font-weight: 600;
  padding: 16px;
  border: none;
  margin: 0;
  cursor: pointer;
`;

export const Menu = styled.nav<Props>`
  width: 160px;
  height: 320px;
  position: absolute;
  top: 56px;
  right: 16px;
  background-color: ${({ theme }) => theme.colors.background};
  border: 1px solid ${({ theme }) => theme.colors.lightGray};
  border-radius: 8px;
  opacity: ${({ $isActive }) => ($isActive ? '1' : '0')};
  visibility: ${({ $isActive }) => ($isActive ? 'visible' : 'hidden')};
  overflow-y: scroll;
  z-index: 99;
  transform: ${({ $isActive }) =>
    $isActive ? 'translateY(0)' : 'translateY(-20px)'};
  transition:
    opacity 0.15s ease,
    transform 0.15s ease,
    visibility 0.15s;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  li:not(:last-child) {
    border-bottom: 1px solid #dddddd;
  }
`;

export const MenuItemInner = styled.button`
  width: 100%;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.dark};
  font-size: 16px;
  line-height: 16px;
  text-align: left;
  padding: 16px;
  border: none;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.brand5};
  }
`;
