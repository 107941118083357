import { FC, ReactNode, useCallback, useRef } from 'react';

import { useDetectOutsideClick } from 'hooks/useDetectOutsideClick';
import { useKeyEffect } from 'hooks/useKeyEffect';
import { Menu, MenuButton, MenuContainer, MenuItemInner } from './styled';

export type MenuItem = {
  id: string;
  text: string;
  onClick: () => void;
};

type Props = {
  children: ReactNode;
  menu: MenuItem[];
};

export const DropdownMenu: FC<Props> = ({ children, menu }) => {
  // Ref
  const itemRef = useRef<HTMLButtonElement>(null);
  const menuRef = useRef<HTMLElement>(null);

  // Hooks
  const [isActive, setIsActive] = useDetectOutsideClick(
    [itemRef, menuRef],
    false
  );

  // Effect for closing menu with key press
  useKeyEffect({
    action: () => setIsActive(false),
    addEventListener: isActive,
    eventKeys: ['Escape'],
    target: window
  });

  const onClickMenu = useCallback(() => {
    setIsActive(!isActive);
  }, [setIsActive, isActive]);

  const onClickMenuItem = useCallback(
    (onClick: () => void) => () => {
      onClick();
      onClickMenu();
    },
    [onClickMenu]
  );

  return (
    <MenuContainer>
      <MenuButton type="button" onClick={onClickMenu} ref={itemRef}>
        {children}
      </MenuButton>
      <Menu ref={menuRef} $isActive={isActive}>
        <ul>
          {menu.map((item) => (
            <li key={item.id}>
              <MenuItemInner
                type="button"
                onClick={onClickMenuItem(item.onClick)}
              >
                {item.text}
              </MenuItemInner>
            </li>
          ))}
        </ul>
      </Menu>
    </MenuContainer>
  );
};
