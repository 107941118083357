import styled, { DefaultTheme } from 'styled-components';

type Props = {
  $variant: string;
};

type StyleProps = Props & {
  theme: DefaultTheme;
};

// Handle background color
function handleBackground({ theme, $variant }: StyleProps) {
  if ($variant === 'light') {
    return theme.colors.lightGray;
  }

  return theme.colors.gray;
}

// Handle color
function handleColor({ theme, $variant }: StyleProps) {
  if ($variant === 'light') {
    return theme.colors.dark;
  }

  return theme.colors.white;
}

export const Container = styled.span<Props>`
  display: inline-flex;
  align-items: center;
  background-color: ${handleBackground};
  color: ${handleColor};
  font-size: 12px;
  line-height: 16px;
  font-weight: bold;
  padding: 4px 8px;
  border-radius: 8px;
`;
