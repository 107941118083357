import { FC, useCallback, useMemo, useState } from 'react';

import { Icon } from 'components/UI/Icon';
import { useTranslations } from 'hooks/useTranslations';
import {
  InputFieldContainer,
  InputFieldInput,
  InputFieldLabel,
  InputFieldButton,
  Required,
  Message
} from './styled';

type Props = {
  id: string;
  label: string;
  required?: boolean;
  isPasswordInput?: boolean;
  defaultValue?: string;
  placeholder?: string;
  disabled?: boolean;
  message?: string;
  error?: any;
  register?: any;
};

export const InputField: FC<Props> = ({
  id,
  label,
  required = false,
  isPasswordInput = false,
  defaultValue,
  placeholder = '',
  disabled = false,
  message,
  error,
  register
}) => {
  const t = useTranslations();

  // State
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const toggleShowPassword = useCallback(
    () => setShowPassword(!showPassword),
    [showPassword]
  );

  // Render input icon
  const passwordIcon = useMemo(() => {
    if (!isPasswordInput) {
      return null;
    }

    return (
      <InputFieldButton
        type="button"
        onClick={toggleShowPassword}
        aria-label={t(
          showPassword ? 'general.hide_password' : 'general.show_password'
        )}
      >
        <Icon
          type={showPassword ? 'hidePassword' : 'showPassword'}
          color="onBackground"
        />
      </InputFieldButton>
    );
  }, [isPasswordInput, showPassword, t, toggleShowPassword]);

  // Message
  const messageText = useMemo(() => {
    if (error && message) {
      return `${message} (${error.message})`;
    }

    if (error) {
      return error.message;
    }

    if (message) {
      return message;
    }

    return null;
  }, [message, error]);

  return (
    <InputFieldContainer>
      <InputFieldLabel htmlFor={id} $error={error}>
        {label}
        {required && <Required>*</Required>}
        {messageText && <Message>{`- ${messageText}`}</Message>}
      </InputFieldLabel>
      <InputFieldInput
        id={id}
        defaultValue={defaultValue}
        placeholder={placeholder}
        type={isPasswordInput && !showPassword ? 'password' : 'text'}
        disabled={disabled}
        $error={error}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...register}
      />
      {passwordIcon}
    </InputFieldContainer>
  );
};
