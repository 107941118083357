import { FC, MouseEvent, ReactNode } from 'react';

import { StyledButton } from './styled';

type ButtonVariant = 'primary' | 'secondary' | 'transparent' | 'danger';

type Props = {
  children?: ReactNode;
  type?: 'button' | 'submit' | 'reset';
  ariaLabel?: string;
  variant?: ButtonVariant;
  disabled?: boolean;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
};

export const Button: FC<Props> = ({
  children,
  type,
  ariaLabel,
  variant = 'primary',
  disabled = false,
  onClick
}) => (
  <StyledButton
    type={type}
    aria-label={ariaLabel}
    $variant={variant}
    disabled={disabled}
    onClick={onClick}
  >
    {children}
  </StyledButton>
);
