import styled from 'styled-components';

export const Description = styled.p`
  margin-bottom: 24px;
`;

export const ActiveLogin = styled.li`
  list-style-type: none;
  margin: 0 0 32px;
`;
