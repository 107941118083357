import styled from 'styled-components';

export const Container = styled.div`
  max-width: 500px;
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 0px auto;
`;

export const IconContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 32px auto;
`;

export const Description = styled.div`
  margin-bottom: 24px;
`;

export const ButtonGrid = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 32px;
`;
