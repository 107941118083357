import styled from 'styled-components';

import { breakpoints } from 'styles';

export const StyledListItem = styled.li`
  width: 100%;
  display: flex;
  align-items: center;
  list-style-type: none;
  padding: 16px;
  border-radius: 8px;
  margin: 0;
  cursor: pointer;

  @media only screen and (min-width: ${breakpoints.l}px) {
    &:hover {
      background-color: ${({ theme }) => theme.colors.brand5};
    }
  }

  @media only screen and (max-width: ${breakpoints.l}px) {
    padding: 16px 0;
  }
`;

export const ListItemText = styled.div`
  width: 100%;
  margin-right: 16px;

  h3 {
    font-weight: 400;
    margin: 0 0 8px;
  }
`;

export const ListItemSupportingText = styled.p`
  color: ${({ theme }) => theme.colors.onBackground};
  margin: 0;
`;
