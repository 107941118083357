import { FC, useCallback, useEffect, useMemo } from 'react';
import { Navigate } from 'react-router-dom';

import { Button } from 'components/UI/Button';
import { ErrorState } from 'components/UI/ErrorState';
import { Heading } from 'components/UI/Heading';
import { Link } from 'components/UI/Link';
import { Loader } from 'components/UI/Loader';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useTranslations } from 'hooks/useTranslations';
import * as routes from 'router/routes';
import { authSelector, loginRedirect, resetAuthError } from 'store/authState';
import { useLoginWithValidateTokenMutation } from 'store/diamApi';
import { localizationSelector } from 'store/localizationState';
import { ButtonGrid } from './styled';

export const LoginPage: FC = () => {
  const dispatch = useAppDispatch();
  const t = useTranslations();
  const [login, result] = useLoginWithValidateTokenMutation();
  const { authenticated, error } = useAppSelector(authSelector);
  const { languageCode } = useAppSelector(localizationSelector);

  useEffect(() => {
    if (!authenticated) {
      login();
    }
  }, [authenticated, login]);

  const onLogin = useCallback(
    () => dispatch(loginRedirect(languageCode)),
    [dispatch, languageCode]
  );

  const onResetError = useCallback(
    () => dispatch(resetAuthError()),
    [dispatch]
  );

  const errorMessage = useMemo(
    () =>
      error === 'session.storage.unavailable'
        ? 'errors.session_storage_unavailable'
        : 'errors.unknown_error',
    [error]
  );

  if (!authenticated && (result.isUninitialized || result.isLoading)) {
    return <Loader />;
  }

  if (error) {
    return (
      <ErrorState message={t(errorMessage)}>
        <Button onClick={onResetError}>{t('login.retry')}</Button>
      </ErrorState>
    );
  }

  if (authenticated) {
    return <Navigate to={routes.PROFILE} />;
  }

  return (
    <>
      <Heading>{t('login.title')}</Heading>
      <p>{t('login.description')}</p>
      <ButtonGrid>
        <Button onClick={onLogin}>{t('login.log_in')}</Button>
        <Link to={routes.RESEND_ACTIVATION} variant="secondary">
          {t('login.resend_activation_link')}
        </Link>
        <Link to={routes.RESET_PASSWORD} variant="transparent">
          {t('login.forgot_password')}
        </Link>
      </ButtonGrid>
    </>
  );
};
