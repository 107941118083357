// Unprotected routes
export const ROOT = '/';
export const LOGIN = '/login';
export const LOGIN_SSO = '/login/sso';
export const AUTHORIZE = '/authorize';
export const RESEND_ACTIVATION = '/resend-activation';
export const ACTIVATE_ACCOUNT = '/activate-account';
export const RESET_PASSWORD = '/reset-password';
export const CHANGE_PASSWORD = '/change-password';
export const CHANGE_EMAIL = '/change-email';
export const DELETE_ACCOUNT = '/delete-account';

// Protected routes
export const PROFILE = '/profile';
export const ACCOUNT_SECURITY = '/account-security';
export const LEARN_MORE = '/learn-more';
