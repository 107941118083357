import { FC, useMemo } from 'react';

import { slideshowImages } from 'assets/images';
import { Container, ImageContainer } from './styled';

export const Carousel: FC = () => {
  const imageCarousel = useMemo(
    () =>
      slideshowImages.map((imageUrl, index) => {
        const zIndex = slideshowImages.length - index;

        return (
          <ImageContainer
            key={imageUrl}
            $backgroundUrl={imageUrl}
            $index={index}
            $zIndex={zIndex}
          />
        );
      }),
    []
  );

  return <Container>{imageCarousel}</Container>;
};
