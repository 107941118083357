import {
  ExtendedUser,
  ExtendedUserResponse,
  Group,
  GroupResponse,
  PasswordPolicy,
  PasswordPolicyResponse,
  ScopeTranslation,
  ScopeTranslationsResponse
} from 'models/diam';

export function serializeResult<T>(result: any): T {
  if (Array.isArray(result)) {
    return result.map((item) => structuredClone(item)) as T;
  }

  return structuredClone(result);
}

export function getExtendedUserFromResponse(
  userAndMetaResponse: ExtendedUserResponse
): ExtendedUser {
  const { data, included, meta } = userAndMetaResponse;
  const { id, attributes } = data;

  const groupsResponse = included?.filter(
    (metadata) => metadata.type === 'groups'
  ) as GroupResponse[];

  const passwordPolicyResponse = included?.find(
    (metadata) => metadata.type === 'password-policies'
  ) as PasswordPolicyResponse;

  const includedGroups: Group[] | null = groupsResponse
    ? groupsResponse.map((group) => ({
        id: group.id,
        name: group.attributes.name,
        groupType: group.attributes.group_type,
        defaultBrand: group.attributes.default_brand,
        description: group.attributes.description
      }))
    : null;

  const includedPasswordPolicy: PasswordPolicy | null = passwordPolicyResponse
    ? {
        id: passwordPolicyResponse.id,
        maximumLength: passwordPolicyResponse.attributes.maximum_length,
        minimumLength: passwordPolicyResponse.attributes.minimum_length,
        requireCapital: passwordPolicyResponse.attributes.require_capital,
        requireLower: passwordPolicyResponse.attributes.require_lower,
        requireNumber: passwordPolicyResponse.attributes.require_number,
        requireSpecial: passwordPolicyResponse.attributes.require_special
      }
    : null;

  return {
    id,
    username: attributes.username,
    customerId: attributes.customer_id,
    salutation: attributes.salutation,
    firstName: attributes.first_name,
    lastName: attributes.last_name,
    country: attributes.country,
    language: attributes.language,
    postalAddress: attributes.postal_address,
    address2: attributes.address2,
    postalCode: attributes.postal_code,
    city: attributes.city,
    telephoneNumber: attributes.telephone_number,
    roles: attributes.roles,
    groups: attributes.groups,
    registrationProvider: attributes.registration_provider,
    lastLogin: attributes.last_login,
    disabled: attributes.disabled,
    mfaActivated: attributes.mfa.activated,
    customAttributes: attributes.custom_attributes,
    includedGroups,
    includedPasswordPolicy,
    meta
  };
}

export function getScopeTranslationsFromResponse(
  scopeTranslationsResponse: ScopeTranslationsResponse
): ScopeTranslation[] {
  return scopeTranslationsResponse.data.map((scopeTranslation) => ({
    language: scopeTranslation.attributes.language,
    translation: scopeTranslation.attributes.translation
  }));
}
