import { createSlice, PayloadAction, Dispatch } from '@reduxjs/toolkit';

import defaultTranslation from 'assets/languages/en.json';
import { Language } from 'models/diam';
import * as localizationService from 'services/localizationService/localizationService';
import type { RootState } from 'store';
import { defaultLanguageCode } from 'utils/constants';
import { determineLanguage } from 'utils/localizationUtils';
import * as localStorage from 'utils/localStorageUtils';

export type LocalizationState = {
  languageCode: string;
  isLoading: boolean;
  translation: localizationService.AppTranslation;
  errors: string[];
};

export const defaultLocalizationState: LocalizationState = {
  languageCode: defaultLanguageCode,
  isLoading: false,
  translation: defaultTranslation,
  errors: []
};

export const localizationSlice = createSlice({
  name: 'localization',
  initialState: defaultLocalizationState,
  reducers: {
    setLanguageCode: (
      state: LocalizationState,
      action: PayloadAction<string>
    ) => {
      const lang = action.payload;

      localStorage.setItem('lang', { lang });

      state.languageCode = lang;
    },
    getTranslation: (state: LocalizationState) => {
      state.isLoading = true;
    },
    getTranslationSuccess: (
      state,
      action: PayloadAction<localizationService.Translation>
    ) => {
      const { language } = action.payload;

      state.isLoading = false;
      state.translation = language;
    },
    getTranslationFailure: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.errors = [action.payload];
    },
    resetLocalizationState() {
      return defaultLocalizationState;
    }
  }
});

// Export actions
export const {
  setLanguageCode,
  getTranslation,
  getTranslationSuccess,
  getTranslationFailure,
  resetLocalizationState
} = localizationSlice.actions;

// Export reducer
export const localizationReducer = localizationSlice.reducer;

// Export selector
export const localizationSelector = (state: RootState) => state.localization;

// Export thunks
export function setLanguage(
  supportedLanguages: Language[],
  providedLanguage?: string,
  storage = localStorage
) {
  return async (dispatch: Dispatch) => {
    const localStorageLanguage = storage.getItem<{ lang: string }>(
      'lang'
    )?.lang;
    const langCode = determineLanguage(
      supportedLanguages,
      localStorageLanguage ?? providedLanguage
    );
    dispatch(setLanguageCode(langCode));
  };
}

export function fetchTranslation(languageCode: string) {
  return async (dispatch: Dispatch) => {
    dispatch(getTranslation());
    try {
      const languageContainer =
        await localizationService.loadLanguage(languageCode);
      dispatch(getTranslationSuccess(languageContainer));
    } catch (e: any) {
      dispatch(getTranslationFailure(e.message));
    }
  };
}
