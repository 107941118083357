function localStorageErrorHandler(fn: () => any) {
  try {
    return fn();
  } catch (e: any) {
    // eslint-disable-next-line no-console
    console.warn(
      'localStorage is not supported by browser settings',
      e?.message
    );

    return null;
  }
}

export function setItem<T>(key: string, value: T): T {
  localStorageErrorHandler(() =>
    localStorage.setItem(key, JSON.stringify(value))
  );

  return value;
}

export function getItem<T>(key: string) {
  const item = localStorageErrorHandler(() => localStorage.getItem(key));

  if (!item) {
    return null as T;
  }

  return JSON.parse(item) as T;
}

export function removeItem(key: string) {
  localStorageErrorHandler(() => localStorage.removeItem(key));
}

export function clear() {
  localStorageErrorHandler(() => localStorage.clear());
}
