import { Link } from 'react-router-dom';
import styled, { DefaultTheme } from 'styled-components';

type Props = {
  $variant: string;
};

type StyleProps = Props & {
  theme: DefaultTheme;
};

// Handle background color
function handleBackground({ theme, $variant }: StyleProps) {
  switch ($variant) {
    case 'transparent':
      return 'transparent';
    case 'secondary':
      return theme.colors.background;
    default:
      return theme.colors.primary;
  }
}

// Handle background color on hover
function handleHoverBackground({ theme, $variant }: StyleProps) {
  if ($variant === 'primary') {
    return theme.colors.brand110;
  }

  return theme.colors.brand5;
}

// Handle color
function handleColor({ theme, $variant }: StyleProps) {
  switch ($variant) {
    case 'transparent':
    case 'secondary':
      return theme.colors.brand;
    default:
      return theme.colors.onPrimary;
  }
}

// Handle border
function handleBorder({ theme, $variant }: StyleProps) {
  if ($variant === 'secondary') {
    return `1px solid ${theme.colors.primary}`;
  }

  return 'none';
}

export const StyledLink = styled(Link)<Props>`
  width: 100%;
  height: 56px;
  background-color: ${handleBackground};
  color: ${handleColor};
  font-size: 16px;
  line-height: 16px;
  font-weight: 600;
  text-decoration: none;
  padding: 16px;
  border: ${handleBorder};
  border-radius: 8px;
  margin: 0;
  cursor: pointer;

  &:hover {
    background-color: ${handleHoverBackground};
  }
`;

export const Content = styled.span`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;
