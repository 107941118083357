import styled from 'styled-components';

export const Description = styled.p`
  margin-bottom: 24px;
`;

export const ConnectedApplication = styled.li`
  list-style-type: none;
  margin: 0 0 32px;
`;

export const ScopesContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;
