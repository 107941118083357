import {
  Middleware,
  MiddlewareAPI,
  isRejectedWithValue
} from '@reduxjs/toolkit';
import { BaseQueryApi } from '@reduxjs/toolkit/query/react';
import { Token as DiamToken } from 'diam-connector/models/token';

import { JsonApiError, JsonApiErrorDto } from 'models/jsonApiError';
import { RootState } from 'store';
import { unauthorizedError } from 'store/authState';
import { displayApiError } from 'store/globalErrorState';

/**
 * Get token from store
 */
export function getToken(api: BaseQueryApi): DiamToken {
  const { accessToken, refreshToken, userId } = (api.getState() as RootState)
    .auth;

  return new DiamToken({
    accessToken: accessToken ?? undefined,
    refreshToken: refreshToken ?? undefined,
    userId: userId ?? undefined
  });
}

/**
 * Wrapper to transform diam-connector calls to RTK Query's expected structure
 */
export async function queryFnWrapper<T>(promise: Promise<T>) {
  try {
    const data = await promise;
    return { data };
  } catch (err: any) {
    const error = JsonApiErrorDto.fromError(err);

    return { error };
  }
}

/**
 * Log errors and display toasts
 */
export const rtkQueryError: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    if (isRejectedWithValue(action)) {
      const error = action.payload as JsonApiError;

      // Special error for 503 or 0 status code
      if (error.status === 503 || error.status === 0) {
        api.dispatch(
          displayApiError({ ...error, code: 'service.unavailable' })
        );
        return next(action);
      }

      // Log out user with invalid token
      if (error.status === 401 || error.code === 'invalid.token') {
        api.dispatch(unauthorizedError());
        api.dispatch(displayApiError({ ...error, code: 'invalid.token' }));
        return next(action);
      }

      if (error) {
        api.dispatch(displayApiError(error));
      }
    }
    return next(action);
  };
