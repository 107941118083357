import { FC, ReactNode } from 'react';

import { StyledList } from './styled';

type Props = {
  children: ReactNode;
};

export const List: FC<Props> = ({ children }) => (
  <StyledList>{children}</StyledList>
);
