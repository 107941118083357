import { FC, useCallback, useEffect, useMemo } from 'react';

import { Button } from 'components/UI/Button';
import { Heading } from 'components/UI/Heading';
import { List } from 'components/UI/List';
import { Modal } from 'components/UI/Modal';
import { useTranslations } from 'hooks/useTranslations';
import {
  useGetActiveSessionsQuery,
  useLogoutEverywhereMutation
} from 'store/diamApi';
import { showSuccessToast } from 'utils/toastUtils';
import { ActiveLogin, Description } from './styled';

type Props = {
  isOpen: boolean;
  close: () => void;
};

export const ActiveLoginsModal: FC<Props> = ({ isOpen, close }) => {
  const t = useTranslations();
  const activeSessions = useGetActiveSessionsQuery();
  const [logoutEverywhere, logoutEverywhereResult] =
    useLogoutEverywhereMutation();

  // Close
  const onClose = useCallback(() => close(), [close]);

  // Click
  const onClick = useCallback(() => logoutEverywhere(), [logoutEverywhere]);

  const disableButton = useMemo(
    () => activeSessions.data?.length === 1,
    [activeSessions.data?.length]
  );

  useEffect(() => {
    if (logoutEverywhereResult.isSuccess) {
      showSuccessToast(
        t('account_security.toast_logout_everywhere_successful')
      );
    }
  }, [logoutEverywhereResult.isSuccess, t]);

  return (
    <Modal
      title={t('account_security.action_active_logins')}
      isOpen={isOpen}
      isLoading={activeSessions.isLoading || logoutEverywhereResult.isLoading}
      onClose={onClose}
    >
      <Description>
        {t('account_security.description_active_logins')}
      </Description>
      <List>
        {activeSessions.data?.map(
          ({ clientId, clientDescription, numberOfSessions }) => (
            <ActiveLogin key={clientId}>
              <Heading type="h3">{clientDescription || clientId}</Heading>
              <p>
                {t('account_security.description_active_logins_sessions', {
                  numberOfSessions
                })}
              </p>
            </ActiveLogin>
          )
        )}
      </List>
      <Description>
        {t('account_security.description_active_logins_log_out_everywhere')}
      </Description>
      <Button type="button" onClick={onClick} disabled={disableButton}>
        {t('account_security.log_out_everywhere')}
      </Button>
    </Modal>
  );
};
