import { FC, useCallback } from 'react';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';

import { Button } from 'components/UI/Button';
import { InputField } from 'components/UI/InputField';
import { Modal } from 'components/UI/Modal';
import { useTranslations } from 'hooks/useTranslations';
import { ExtendedUser } from 'models/diam';
import { useUpdateUserMutation } from 'store/diamApi';
import { showSuccessToast } from 'utils/toastUtils';
import { InputFieldContainer } from './styled';

type Props = {
  user: ExtendedUser;
  isOpen: boolean;
  close: () => void;
};

export const EditPhoneNumberModal: FC<Props> = ({ user, isOpen, close }) => {
  const t = useTranslations();
  const [updateUser] = useUpdateUserMutation();

  // useForm
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  // Close
  const onClose = useCallback(() => close(), [close]);

  // Submit
  const onSubmit: SubmitHandler<FieldValues> = useCallback(
    ({ phoneNumber }) => {
      updateUser({
        telephoneNumber: phoneNumber.trim()
      });
      showSuccessToast(t('profile.phone_number_update_successful'));
      onClose();
    },
    [onClose, t, updateUser]
  );

  return (
    <Modal
      title={t('profile.action_edit_phone_number')}
      isOpen={isOpen}
      onClose={onClose}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputFieldContainer>
          <InputField
            id="phoneNumber"
            label={t('profile.label_phone_number')}
            defaultValue={user.telephoneNumber}
            placeholder={t('profile.placeholder_phone_number')}
            error={errors.phoneNumber}
            register={register('phoneNumber', {
              maxLength: {
                value: 50,
                message: t('profile.validation_phone_number')
              }
            })}
          />
        </InputFieldContainer>
        <Button type="submit">{t('profile.save')}</Button>
      </form>
    </Modal>
  );
};
