import { toast } from 'react-toastify';

export const toastTypes = {
  SUCCESS: 'success',
  INFO: 'info',
  WARNING: 'warning',
  ERROR: 'error'
} as const;

export type ToastType = (typeof toastTypes)[keyof typeof toastTypes];

export const showToast = (
  message: string,
  type: ToastType = toastTypes.ERROR
) => {
  toast[type](message);
};

export const showErrorToast = (message: string) =>
  showToast(message, toastTypes.ERROR);
export const showWarningToast = (message: string) =>
  showToast(message, toastTypes.WARNING);
export const showInfoToast = (message: string) =>
  showToast(message, toastTypes.INFO);
export const showSuccessToast = (message: string) =>
  showToast(message, toastTypes.SUCCESS);
