import { FC, useCallback, useState } from 'react';

import { useContext } from 'components/fragments/PrivatePageWrapper';
import { ActiveLoginsModal } from 'components/modals/ActiveLoginsModal';
import { ChangeEmailAddressModal } from 'components/modals/ChangeEmailAddressModal';
import { ChangePasswordModal } from 'components/modals/ChangePasswordModal';
import { ConnectedApplicationsModal } from 'components/modals/ConnectedApplicationsModal';
import { DeleteAccountModal } from 'components/modals/DeleteAccountModal';
import { ServicesModal } from 'components/modals/ServicesModal';
import { Heading } from 'components/UI/Heading';
import { List } from 'components/UI/List';
import { ListItem } from 'components/UI/ListItem';
import { Section } from 'components/UI/Section';
import { useTranslations } from 'hooks/useTranslations';

export const AccountSecurityPage: FC = () => {
  const t = useTranslations();
  const { user } = useContext();

  const [changePasswordModal, setChangePasswordModal] =
    useState<boolean>(false);
  const [changeEmailAddressModal, setChangeEmailAddressModal] =
    useState<boolean>(false);
  const [servicesModal, setServicesModal] = useState<boolean>(false);
  const [activeLoginsModal, setActiveLoginsModal] = useState<boolean>(false);
  const [connectedApplicationsModal, setConnectedApplicationsModal] =
    useState<boolean>(false);
  const [deleteAccountModal, setDeleteAccountModal] = useState<boolean>(false);

  const onChangePasswordOpen = useCallback(
    () => setChangePasswordModal(true),
    []
  );
  const onChangePasswordClose = useCallback(
    () => setChangePasswordModal(false),
    []
  );
  const onChangeEmailAddressOpen = useCallback(
    () => setChangeEmailAddressModal(true),
    []
  );
  const onChangeEmailAddressClose = useCallback(
    () => setChangeEmailAddressModal(false),
    []
  );
  const onServicesOpen = useCallback(
    () => setServicesModal(true),
    [setServicesModal]
  );
  const onServicesClose = useCallback(
    () => setServicesModal(false),
    [setServicesModal]
  );
  const onActiveLoginsOpen = useCallback(
    () => setActiveLoginsModal(true),
    [setActiveLoginsModal]
  );
  const onActiveLoginsClose = useCallback(
    () => setActiveLoginsModal(false),
    [setActiveLoginsModal]
  );
  const onConnectedApplicationsOpen = useCallback(
    () => setConnectedApplicationsModal(true),
    [setConnectedApplicationsModal]
  );
  const onConnectedApplicationsClose = useCallback(
    () => setConnectedApplicationsModal(false),
    [setConnectedApplicationsModal]
  );
  const onDeleteAccountOpen = useCallback(
    () => setDeleteAccountModal(true),
    [setDeleteAccountModal]
  );
  const onDeleteAccountClose = useCallback(
    () => setDeleteAccountModal(false),
    [setDeleteAccountModal]
  );

  return (
    <>
      <Heading>{t('account_security.title')}</Heading>
      <Section>
        <Heading type="h2">
          {t('account_security.heading_password_and_email_address')}
        </Heading>
        <List>
          <ListItem
            headline={t('account_security.action_change_password')}
            supportingText="●●●●●●●●"
            onClick={onChangePasswordOpen}
          />
          <ListItem
            headline={t('account_security.action_change_email_address')}
            supportingText={user.username}
            onClick={onChangeEmailAddressOpen}
          />
        </List>
      </Section>
      <Section>
        <Heading type="h2">
          {t('account_security.heading_applications')}
        </Heading>
        <List>
          <ListItem
            headline={t('account_security.action_services')}
            supportingText={t('account_security.action_services_description')}
            onClick={onServicesOpen}
          />
          <ListItem
            headline={t('account_security.action_active_logins')}
            supportingText={t(
              'account_security.action_active_logins_description'
            )}
            onClick={onActiveLoginsOpen}
          />
          <ListItem
            headline={t('account_security.action_connected_applications')}
            supportingText={t(
              'account_security.action_connected_applications_description'
            )}
            onClick={onConnectedApplicationsOpen}
          />
        </List>
      </Section>
      <Section>
        <Heading type="h2">
          {t('account_security.heading_account_deletion')}
        </Heading>
        <List>
          <ListItem
            headline={t('account_security.action_delete_your_account')}
            supportingText={t(
              'account_security.action_delete_your_account_description'
            )}
            onClick={onDeleteAccountOpen}
          />
        </List>
      </Section>
      <ChangePasswordModal
        user={user}
        isOpen={changePasswordModal}
        close={onChangePasswordClose}
      />
      <ChangeEmailAddressModal
        user={user}
        isOpen={changeEmailAddressModal}
        close={onChangeEmailAddressClose}
      />
      <ServicesModal
        user={user}
        isOpen={servicesModal}
        close={onServicesClose}
      />
      <ActiveLoginsModal
        isOpen={activeLoginsModal}
        close={onActiveLoginsClose}
      />
      <ConnectedApplicationsModal
        isOpen={connectedApplicationsModal}
        close={onConnectedApplicationsClose}
      />
      <DeleteAccountModal
        user={user}
        isOpen={deleteAccountModal}
        close={onDeleteAccountClose}
      />
    </>
  );
};
