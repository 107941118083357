import * as Sentry from '@sentry/react';
import { FC, useCallback } from 'react';
import { Outlet } from 'react-router-dom';

import { Button } from 'components/UI/Button';
import { Heading } from 'components/UI/Heading';
import { Icon } from 'components/UI/Icon';
import { useTranslations } from 'hooks/useTranslations';
import { ButtonGrid, Container, Description, IconContainer } from './styled';

type FallbackProps = {
  resetError: () => void;
};

const FallbackError: FC<FallbackProps> = ({ resetError }) => {
  const t = useTranslations();
  const onClick = useCallback(() => resetError(), [resetError]);

  return (
    <Container>
      <IconContainer>
        <Icon type="error" color="error" size="extraLarge" />
      </IconContainer>
      <Heading>{t('crash.title')}</Heading>
      <Description>{t('crash.description')}</Description>
      <ButtonGrid>
        <Button onClick={onClick}>{t('crash.reset')}</Button>
      </ButtonGrid>
    </Container>
  );
};

const fallBack = (props: FallbackProps) => (
  <FallbackError resetError={props.resetError} />
);

export const ErrorBoundary: FC = () => (
  <Sentry.ErrorBoundary fallback={fallBack} showDialog>
    <Outlet />
  </Sentry.ErrorBoundary>
);
