import { PkceBrowserUtil } from 'diam-connector/utils/pkce-browser';

import * as config from 'config';
import * as sessionStorage from 'utils/sessionStorageUtils';

export async function getDiamLoginUrl(languageCode: string) {
  const { codeVerifier, codeChallenge, codeChallengeMethod } =
    await PkceBrowserUtil.generateCodeVerifierAndCodeChallenge();

  sessionStorage.checkAvailability();
  sessionStorage.setItem('codeVerifier', { codeVerifier });
  sessionStorage.setItem('codeChallenge', { codeChallenge });

  const { clientId, diamApi } = config;
  const diamUrl = [`${diamApi}/oauth2/authorize`];
  const redirectUri = `${window.location.origin}/authorize`;

  diamUrl.push(`?client_id=${clientId}`);
  diamUrl.push(`&redirect_uri=${redirectUri}`);
  diamUrl.push(`&code_challenge=${codeChallenge}`);
  diamUrl.push(`&code_challenge_method=${codeChallengeMethod}`);
  diamUrl.push(`&language=${languageCode}`);

  return diamUrl.join('');
}

export async function getDiamSsoUrl(ssoToken: string, languageCode: string) {
  const { codeVerifier, codeChallenge, codeChallengeMethod } =
    await PkceBrowserUtil.generateCodeVerifierAndCodeChallenge();

  sessionStorage.checkAvailability();
  sessionStorage.setItem('codeVerifier', { codeVerifier });
  sessionStorage.setItem('codeChallenge', { codeChallenge });

  const { clientId, diamApi } = config;
  const diamUrl = [`${diamApi}/oauth2/login`];
  const redirectUri = `${window.location.origin}/authorize`;

  diamUrl.push(`?client_id=${clientId}`);
  diamUrl.push(`&redirect_uri=${redirectUri}`);
  diamUrl.push(`&sso=${ssoToken}`);
  diamUrl.push('&direct_grant=true');
  diamUrl.push(`&code_challenge=${codeChallenge}`);
  diamUrl.push(`&code_challenge_method=${codeChallengeMethod}`);
  diamUrl.push(`&language=${languageCode}`);

  return diamUrl.join('');
}

export function getCodeVerifier() {
  return sessionStorage.getItem<{ codeVerifier: string }>('codeVerifier')
    ?.codeVerifier;
}
