import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ErrorCodeTypes, GlobalErrorState } from 'models/errors';
import { JsonApiError } from 'models/jsonApiError';
import { RootState } from 'store';
import { getErrorCode } from 'utils/errorUtils';

export const defaultErrorState: GlobalErrorState = {
  counter: 0,
  messageKey: '',
  type: ErrorCodeTypes.HIDDEN
};

// Export slice
export const globalErrorSlice = createSlice({
  name: 'error',
  initialState: defaultErrorState,
  reducers: {
    displayApiError: (state, action: PayloadAction<JsonApiError>) => {
      const { messageKey, type } = getErrorCode(action.payload.code);

      state.messageKey = messageKey;
      state.type = type;
      state.counter += 1;
    },
    displayGlobalError: (state, action: PayloadAction<string>) => {
      const { messageKey, type } = getErrorCode(action.payload);

      state.messageKey = messageKey;
      state.type = type;
      state.counter += 1;
    }
  }
});

// Export selectors
export const globalErrorSelector = (state: RootState) => state.globalError;

// Export actions
export const { displayGlobalError, displayApiError } = globalErrorSlice.actions;

// Export reducers
export const globalErrorReducer = globalErrorSlice.reducer;
