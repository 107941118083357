import styled, { keyframes } from 'styled-components';

import { breakpoints } from 'styles';

type Props = {
  $backgroundUrl: string;
  $index: number;
  $zIndex: number;
};

export const Container = styled.aside`
  width: 50%;
  height: 100%;
  position: relative;
  flex: 1;

  @media only screen and (max-width: ${breakpoints.l}px) {
    display: none;
  }
`;

const imageAnimation = keyframes`
  12% {
    opacity: 1;
  }
  20%, 92% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const ImageContainer = styled.div<Props>`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: url(${({ $backgroundUrl }) => $backgroundUrl});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: ${({ $zIndex }) => $zIndex};
  animation: ${imageAnimation} 24s linear infinite 0s;
  animation-delay: ${({ $index }) => `${$index * 4}s`};
`;
