import { FC, useCallback, useMemo } from 'react';

import { Heading } from 'components/UI/Heading';
import { Modal } from 'components/UI/Modal';
import { useTranslations } from 'hooks/useTranslations';
import { faqItems } from 'utils/constants';
import { Answer, Category, Description, Question } from './styled';

type Props = {
  isOpen: boolean;
  close: () => void;
};

export const FaqModal: FC<Props> = ({ isOpen, close }) => {
  const t = useTranslations();

  // Close
  const onClose = useCallback(() => close(), [close]);

  const faq = useMemo(
    () =>
      faqItems.map((faqItem) => (
        <Category key={faqItem.category}>
          <Heading type="h3">{t(`faq.${faqItem.category}.heading`)}</Heading>
          {faqItem.questions.map((item) => (
            <div key={`${faqItem.category}-${item}`}>
              <Question>
                {t(`faq.${faqItem.category}.question_${item}`)}
              </Question>
              <Answer>{t(`faq.${faqItem.category}.answer_${item}`)}</Answer>
            </div>
          ))}
        </Category>
      )),
    [t]
  );

  return (
    <Modal title={t('learn_more.action_faq')} isOpen={isOpen} onClose={onClose}>
      <Description>{t('learn_more.description_faq')}</Description>
      {faq}
    </Modal>
  );
};
