import errorCodes from 'assets/data/errorCodes.json';
import { ErrorCode, ErrorCodeKey } from 'models/errors';

export function getErrorCode(code: string): ErrorCode {
  const errorCode = errorCodes[code as ErrorCodeKey];

  if (!errorCode) {
    return errorCodes.unknown as ErrorCode;
  }

  return errorCode as ErrorCode;
}
