import defaultTranslation from 'assets/languages/en.json';
import { defaultLanguageCode } from 'utils/constants';

export type AppTranslation = typeof defaultTranslation;

export type Translation = { code: string; language: AppTranslation };

function replaceKeyWithValue(str: string, values: object): string {
  let valueStr: string = str;
  Object.entries(values).forEach(([key, value]) => {
    const selector = `{${key}}`;

    if (valueStr.includes(selector)) {
      valueStr = valueStr.replace(selector, value);
    }
  });

  return valueStr;
}

function translateFromLanguage(
  key: string,
  language: AppTranslation,
  parameters: Record<string, any> = {}
) {
  const sections = key.split('.');
  let lookUpObject: any = language;
  let foundKey = null;

  sections.forEach((section) => {
    if (typeof lookUpObject[section] === 'object') {
      lookUpObject = lookUpObject[section];
    } else {
      foundKey = lookUpObject[section];
    }
  });

  if (foundKey) {
    return replaceKeyWithValue(foundKey, parameters);
  }

  return foundKey;
}

export function translate(
  key: string,
  language: AppTranslation,
  parameters?: Record<string, any>
): string {
  const translationKey = String(key).trim();
  if (!translationKey) {
    return '';
  }

  let translation = translateFromLanguage(translationKey, language, parameters);

  if (!translation) {
    translation = translateFromLanguage(
      translationKey,
      defaultTranslation,
      parameters
    );
  }

  return translation ?? translationKey;
}

/**
 * Try to load a language by a language code.
 * If we fail to load the desired language, the default language is returned.
 */
export async function loadLanguage(langCode: string): Promise<Translation> {
  try {
    const { default: data } = await import(
      `../../assets/languages/${langCode}.json`
    );

    return { code: langCode, language: { ...data } };
  } catch (err) {
    return { code: defaultLanguageCode, language: defaultTranslation };
  }
}
