import { FC, useMemo } from 'react';

import { DropdownMenu } from 'components/UI/DropdownMenu';
import { Icon } from 'components/UI/Icon';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useGetTranslationsQuery } from 'store/diamApi';
import { localizationSelector, setLanguageCode } from 'store/localizationState';
import { getLanguageName } from 'utils/userUtils';
import { ButtonContentContainer, Container } from './styled';

export const LanguageSelector: FC = () => {
  const dispatch = useAppDispatch();
  const { languageCode } = useAppSelector(localizationSelector);
  const translations = useGetTranslationsQuery();

  const langMenu = useMemo(() => {
    if (!translations.data || translations.isError) {
      return [];
    }

    return translations.data?.map((language) => ({
      id: language.langCode,
      text: language.name,
      onClick: () => dispatch(setLanguageCode(language.langCode))
    }));
  }, [dispatch, translations.data, translations.isError]);

  return (
    <Container>
      <DropdownMenu menu={langMenu}>
        <ButtonContentContainer>
          {getLanguageName(languageCode, translations.data)}
          <Icon type="globe" color="brand" />
        </ButtonContentContainer>
      </DropdownMenu>
    </Container>
  );
};
