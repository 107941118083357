import { FC, useCallback } from 'react';

import { Modal } from 'components/UI/Modal';
import { UserGroups } from 'components/UI/UserGroups';
import { useTranslations } from 'hooks/useTranslations';
import { ExtendedUser } from 'models/diam';
import { getUserGroups } from 'utils/userUtils';
import { Description } from './styled';

type Props = {
  user: ExtendedUser;
  isOpen: boolean;
  close: () => void;
};

export const ServicesModal: FC<Props> = ({ user, isOpen, close }) => {
  const t = useTranslations();

  // Close
  const onClose = useCallback(() => close(), [close]);

  return (
    <Modal
      title={t('account_security.action_services')}
      isOpen={isOpen}
      onClose={onClose}
    >
      <Description>{t('account_security.description_services')}</Description>
      <UserGroups groups={getUserGroups(user)} />
    </Modal>
  );
};
