import { FC } from 'react';
import { Link, Outlet } from 'react-router-dom';

import husqvarnaGroupLogo from 'assets/vectors/husqvarna-group-logo.svg';
import { Carousel } from 'components/UI/Carousel';
import { Footer } from 'components/UI/Footer';
import { LanguageSelector } from 'components/UI/LanguageSelector';
import { useTranslations } from 'hooks/useTranslations';
import * as routes from 'router/routes';
import { PageContainer, Header, HeaderLogo, Main, Content } from './styled';

export const PublicPageWrapper: FC = () => {
  const t = useTranslations();

  return (
    <PageContainer>
      <Carousel />
      <Content>
        <Header>
          <Link to={routes.LOGIN}>
            <HeaderLogo
              src={husqvarnaGroupLogo}
              alt={t('general.go_to_login')}
            />
          </Link>
          <LanguageSelector />
        </Header>
        <Main>
          <Outlet />
        </Main>
        <Footer />
      </Content>
    </PageContainer>
  );
};
